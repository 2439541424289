import useStyles from "./styles";
import { useEffect, useState } from "react";
import axios from "axios";
import endPoints from "../../../config/Api";
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
  getUserName,
} from "../../Authorization/Services/authService";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Loader from "../Loader";

const Home = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [data, setData] = useState([]);
  let token = getAccessToken();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      if (isTokenExpired(token)) {
        token = await refreshAccessToken(); // Refresh the token if expired
      }

      const response = await axios.post(
        `${endPoints.baseUrl}/data_views/${endPoints.accounts.D_Page_Name}`,
        {
          dataViewParameters: {
            AccountType: '"Checking","Savings"',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setData(response.data.data); // Set data on successful API call
    } catch (err) {
      console.error(err);
      localStorage.clear();
      navigate("/login"); // Navigate to login if error occurs
    } finally {
      setLoading(false); // Stop loading spinner once data is fetched or error occurred
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const displayAmount = (amount, currency) => {
    const decimalFlag = "JPY" === currency ? 0 : 2;
    const formattedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimalFlag,
      maximumFractionDigits: decimalFlag,
    }).format(amount);
    return formattedAmount === "NaN" ? "0.0" : formattedAmount;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid className={classes.rootContainer}>
            <Grid container>
              <Grid size={{ md: 6, xs: 6 }}>
                <Grid container>
                  <Grid size={{ md: 12, xs: 12 }}>
                    <Typography variant="h6" className={classes.txt}>
                      Welcome to Online Banking
                    </Typography>
                  </Grid>
                  <Grid size={{ md: 12, xs: 12 }}>
                    <Typography
                      variant="h6"
                      className={`${classes.txt} ${classes.username}`}
                    >
                      {getUserName()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{ md: 6, xs: 6 }} className={classes.root}>
                <Button
                  color="primary"
                  // onClick={handleClick}
                  size="small"
                  className={classes.button}
                >
                  Open a New Account
                </Button>
              </Grid>
            </Grid>
            <Typography variant="body1" className={classes.a_heading}>
              Personal Accounts *
            </Typography>
            <Grid container spacing={2}>
              {data &&
                data.map((row, index) => {
                  return (
                    <>
                      <Grid size={{ xs: 6 }} key={index}>
                        <Card
                          // key={index}
                          className={classes.accountCard2}
                        >
                          <CardContent className={classes.acContent2}>
                            <div className={classes.qBContainer}>
                              <Button
                                variant="contained"
                                className={classes.quickBtn}
                              >
                                Quick View
                              </Button>
                            </div>
                            <div className={classes.detailsContainer}>
                              <Typography
                                variant="body1"
                                className={classes.details}
                              >
                                <b>
                                  {displayAmount(
                                    row.AccountBalance,
                                    row?.AccountCurrency
                                  )}
                                  &nbsp;&nbsp;
                                  {row?.AccountCurrency}
                                </b>
                              </Typography>
                              <div className={classes.subDetails}>
                                <Typography
                                  variant="body2"
                                  className={classes.acInfo}
                                >
                                  {row.AccountNumber}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className={classes.acInfo}
                                >
                                  {row.AccountType} Account
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Home;
