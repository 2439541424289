import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchAccounts_Data,
  fetchDataForSendAmount,
  fetchDataFromAccount,
  fetchDataForReceiverAmount,
  fetchDataForReceiverCurrency,
} from "./SendPaymentApiBuilders";
import { validateAndSaveDetails } from "../../components/CommonService/sendPaymentService";

export const validateAndSaveDetailsForEditBeneInSendPaymentFlow =
  createAsyncThunk(
    "sendPaymentFlow_beneficiary/validateAndSaveDetailsForEditBeneInSendPaymentFlow",
    async (
      { editableBeneDetails, nextAssignmentId, eTag, refreshFor },
      { rejectWithValue }
    ) => {
      try {
        const response = await validateAndSaveDetails(
          editableBeneDetails,
          nextAssignmentId,
          eTag,
          refreshFor
        );

        // Check for a valid response (e.g., HTTP 2xx status)
        if (response.status >= 200 && response.status < 300) {
          return response.data?.data?.caseInfo?.content?.SelectedBeneDetails;
        }
      } catch (error) {
        // Handle unexpected errors
        return rejectWithValue({
          status: error.status,
          message: error,
        });
      }
    }
  );

const initialState = {
  accounts: [],
  data: null,
  editBeneData: null,
  availableToPayCurrencyList: [],
  nextAssignmentId: null,
  caseId: null,
  eTag: null,
  availableToPayCountryNamesList: [],
  receiversCountryName: "",
  selectedBeneDetails: null,
  previousData: null,
  purposeCodeList: [],
  reviewDetails: [],
  finalResponse: null,
  showReviewBeneficiary: false,
  loading: true,
  error: null,
  tabIndex: 0,
};
// reducers
const SendPaymentSlice = createSlice({
  name: "sendPaymentDataFlow",
  initialState,
  reducers: {
    setAccountsData(state, action) {
      state.accounts = action.payload;
    },
    setAvailable_ToPayCurrencyList(state, action) {
      state.availableToPayCurrencyList = action.payload;
    },
    setAvailable_ToPayCountryNamesList(state, action) {
      state.availableToPayCountryNamesList = action.payload;
    },
    setData(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setTabIndex(state, action) {
      state.tabIndex = action.payload;
    },
    resetInitialState: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts_Data.fulfilled, (state, action) => {
        state.eTag = action.payload.etag;
        state.caseId = action.payload?.data?.data?.caseInfo?.ID.split(" ")[1];
        state.nextAssignmentId = action.payload?.data?.nextAssignmentInfo?.ID;
        state.data = action.payload?.data?.data?.caseInfo?.content;
      })
      .addCase(fetchDataFromAccount.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchDataForSendAmount.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchDataForReceiverAmount.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchDataForReceiverCurrency.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(
        validateAndSaveDetailsForEditBeneInSendPaymentFlow.fulfilled,
        (state, action) => {
          console.log("---", action.payload);
          state.editBeneData = { ...state.editBeneData, ...action.payload };
          state.error = null;
        }
      )
      .addCase(
        validateAndSaveDetailsForEditBeneInSendPaymentFlow.rejected,
        (state, action) => {
          console.log("action.payload", action.payload);
          state.error = action.payload;
        }
      );
  },
});

export const {
  setAccountsData,
  setTabIndex,
  setData,
  setLoading,
  setAvailable_ToPayCurrencyList,
  setAvailable_ToPayCountryNamesList,
  resetInitialState,
} = SendPaymentSlice.actions;
export default SendPaymentSlice.reducer;
