import React, { useState } from "react";
import useStyles from "./styles";
import { Link, Outlet } from "react-router-dom";
import { Apps, Home, Send, SwapHoriz } from "@mui/icons-material";
//import DrawerMenu from "./drawerMenu";
import Grid from "@mui/material/Grid2";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { useLocation } from "react-router-dom";

const Dataview = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      {/*  <DrawerMenu
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      /> */}
      <Grid container direction="column" style={{ backgroundColor: "#5649a1" }}>
        <Grid container className={classes.middleContainer}>
          <Grid size={{ xs: 12 }} className={classes.contentContainer}>
            <Outlet />
          </Grid>
        </Grid>
        <Grid
          size={{ md: 12 }}
          className={classes.footerContainer}
          style={{ position: "sticky", bottom: "0", zIndex: "10" }}
        >
          <Grid
            className={`${classes.flexCenter} ${
              location?.pathname === "/" ? classes.bgcolor : ""
            }`}
          >
            <Link to="/" className={classes.linkIcons}>
              <HomeOutlinedIcon className={classes.footerIcons} />
              <span className={classes.iconText}>Home</span>
            </Link>
          </Grid>
          <Grid
            className={`${classes.flexCenter} ${
              location?.pathname === "/recentpayments" ? classes.bgcolor : ""
            }`}
          >
            <Link to="/recentpayments" className={classes.linkIcons}>
              <PaymentsOutlinedIcon className={classes.footerIcons} />
              <span className={classes.iconText}>All Payments</span>
            </Link>
          </Grid>
          <Grid
            className={`${classes.flexCenter} ${
              location?.pathname === "/sendpayment" ? classes.bgcolor : ""
            }`}
          >
            <Link to="/sendpayment" className={classes.linkIcons}>
              <Send className={classes.footerIcons} />
              <span className={classes.iconText}>Send Payments</span>
            </Link>
          </Grid>
          {/*  <Grid  className={classes.flexCenter}>
            <Link onClick={handleDrawerOpen} className={classes.linkIcons}>
              <Apps className={classes.footerIcons} />
              More
            </Link>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};

export default Dataview;
