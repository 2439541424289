import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import CountrySpecificComponent from "../SendPayment/CountrySpecificComponent";
import useStyles from "./styles";
import Flag from "react-world-flags";
import Loader from "../Loader";
import { getCountryCode } from "../../CommonUtils/CommonUtils";
import { bicfiRefreshCountries } from "../../CommonUtils/CommonUtils";
import { countryGroup1 } from "../../CommonUtils/CommonUtils";
import { countryGroup2 } from "../../CommonUtils/CommonUtils";
import {
  setFormState,
  setEditSubmitFlag,
  setError,
  resetInitialState,
  setCountryNameFor,
  validateEditBeneficiary,
  editSubmitBeneficiaryDetails,
} from "../../../Redux/Reducers/editBeneficiaryReducer";

const EditBeneficiary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const editBeneficiaryData = useSelector((data) => data?.editBeneficiary);
  const { state } = location || {};
  const { row } = location.state || {};
  const [data] = useState(state);

  const country = editBeneficiaryData?.formState?.Country;

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    // Prepare the updated value
    const updatedValue =
      name === "CdtrAcctIdIBAN" ||
      name === "BICFI" ||
      name === "PostCode" ||
      (country === "India" && name === "ClrSysMmbId")
        ? value.toUpperCase()
        : value;

    dispatch(
      setFormState({
        [name]: updatedValue, // Update the specific field
      })
    );
  };

  useEffect(() => {
    dispatch(setCountryNameFor(data?.country));
  }, [data?.country]);
  const handleRefreshInputBlur = (event) => {
    const { name } = event.target;

    let refreshFor;

    if (countryGroup1.includes(country)) {
      if (name === "BICFI") refreshFor = ".NewBeneDetails.BICFI";
      else if (name === "CdtrAcctIdIBAN")
        refreshFor = ".NewBeneDetails.CdtrAcctIdIBAN";
      else if (name === "ClrSysMmbId")
        refreshFor = ".NewBeneDetails.ClrSysMmbId";
    } else if (countryGroup2.includes(country)) {
      if (name === "BICFI") refreshFor = ".NewBeneDetails.BICFI";
      else if (name === "ClrSysMmbId")
        refreshFor = ".NewBeneDetails.ClrSysMmbId";
    } else {
      refreshFor = bicfiRefreshCountries.includes(country)
        ? ".NewBeneDetails.BICFI"
        : ".NewBeneDetails.CdtrAcctIdIBAN";
    }

    dispatch(
      validateEditBeneficiary({
        assignmentID: editBeneficiaryData?.assignmentID,
        formState: editBeneficiaryData?.formState,
        etag: editBeneficiaryData?.etag,
        refreshFor: refreshFor,
      })
    );
  };

  const handleValidate = () => {
    const { Edit, ...state } = editBeneficiaryData?.formState;
    const refreshFor = ".NewBeneDetails.Validate";
    setLoading(true);
    try {
      dispatch(
        validateEditBeneficiary({
          assignmentID: editBeneficiaryData?.assignmentID,
          formState: editBeneficiaryData?.formState,
          etag: editBeneficiaryData?.etag,
          refreshFor: refreshFor,
        })
      )
        .then((res) => {
          if (res.payload && res?.meta.requestStatus === "fulfilled") {
            dispatch(setEditSubmitFlag(true));
            dispatch(setError(""));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = () => {
    const { Edit, message, pyImage, classID, EnterBICCode, ...state } =
      editBeneficiaryData?.formState;
    setLoading(true);
    // console.log(state);
    dispatch(
      editSubmitBeneficiaryDetails({
        assignmentID: editBeneficiaryData?.assignmentID,
        formState: state,
        etag: editBeneficiaryData?.etag,
      })
    )
      .then((res) => {
        if (res.payload?.confirmationNote) {
          handleClose();
          navigate("/beneficiaries");
        }
      })
      .catch((error) => {
        console.error("Dispatch failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    dispatch(resetInitialState());
    navigate("/beneficiaries");
  };

  return (
    <div>
      <Box className={classes.rootContainer}>
        <Grid>
          <Typography className={classes.title}>
            Edit Beneficiary Details
          </Typography>
        </Grid>
        <Divider className={classes.divider} />
        <Grid className={classes.contentPadding}>
          <Grid className={classes.PayCountry}>
            <Typography className={classes.textstyle}>
              Pay - to Country :{" "}
            </Typography>
            <TextField
              value={editBeneficiaryData?.formState?.Country}
              disabled
              size="small"
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Flag
                        code={getCountryCode(
                          editBeneficiaryData?.formState?.Country
                        )}
                        className={classes.countryFlag}
                      />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          {editBeneficiaryData?.formState?.Country && (
            <>
              <CountrySpecificComponent
                addNewBene={true}
                row={row}
                editBene={true}
                handleChange={handleChange}
                newCountryName={editBeneficiaryData?.formState?.Country}
                handleRefreshInputBlur={handleRefreshInputBlur}
                handleValidate={handleValidate}
              />
            </>
          )}
          {loading && <Loader />}
          <Grid
            container
            spacing={2}
            style={{
              marginTop: "5%",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid md={6}>
              <Button className={classes.cancel} onClick={handleClose}>
                Cancel
              </Button>
            </Grid>

            {!editBeneficiaryData?.submitFlag && (
              <Grid md={6}>
                <Button className={classes.submit} onClick={handleValidate}>
                  Validate
                </Button>
              </Grid>
            )}
            {editBeneficiaryData?.submitFlag && (
              <Grid md={6}>
                <Button className={classes.submit} onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default EditBeneficiary;
