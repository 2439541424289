import { makeStyles } from "@mui/styles";

const MobileStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    // boxShadow: theme.shadows[3],
    borderRadius: "30px",
    overflow: "hidden",
    margin: "3%",
  },
  flex: {
    display: "flex",
  },
  countryFlag: {
    width: "20px !important",
    height: "20px !important",
    borderRadius: " 50% !important",
    objectFit: "cover !important ",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "1.25rem !important ",
    fontWeight: "bold !important ",
    color: "#7270a0 !important ",
  },
  selectedRow: {
    backgroundColor: "#e0e0e0 !important ",
  },
  editIcon: {
    background: "#52459f !important ",
    color: "#fff !important ",
    padding: "3px !important ",
    borderRadius: "8px !important",
    cursor: "pointer !important",
    marginLeft: "10vw!important",
  },
  currencyStyle: {
    marginLeft: "5px",
    width: "20px !important ",
    height: "20px !important ",
    borderRadius: "50% !important ",
  },
  InfoOutlinedIcon: {
    color: "#52459f !important ",
    fontSize: "1.2rem !important ",
    marginLeft: "0px !important ",
  },
  textFont: {
    fontSize: "13px !important ",
  },
  textstyle: {
    fontSize: "10px !important ",
  },
  textCenter: {
    textAlign: "-webkit-center !important ",
  },
  finalScreen: {
    position: "relative !important ",
    padding: "20px !important ",
    borderRadius: "8px !important ",
  },
  ScreenStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 1,
  },
  TransactionHeader: {
    fontSize: "16px !important ",
    color: "#52459f",
    fontWeight: "700 !important ",
  },
  tabHeading: {
    textTransform: "none !important",
    fontSize: "1rem !important ",
    padding: "0px !important ",
    color: "black !important ",
    "&.Mui-selected": {
      color: "#52459f !important",
    },
  },
  conToUppercase: {
    "& input": {
      textTransform: "uppercase",
    },
  },
  textfield: {
    marginTop: "3px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      /* border: 'none',   */
      borderRadius: "20px",
    },
    "& input": {
      paddingLeft: "0px !important",
    },
  },
  required: {
    color: "red",
    fontWeight: "bold",
  },
  Infoicon: {
    color: "#ba8e23 !important ",
    fontSize: "1.5rem",
    marginTop: "11px",
  },
  infoIcon: {
    fill: "#52439E !important",
    fontSize: "1.2rem !important ",
  },
  imgDisplay: {
    display: "flex !important",
    gap: "10px !important",
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "lightgrey !important",
    paddingLeft: "8px !important",
    marginTop: "6px !important ",
  },
  acctInput: {
    padding: "2.5% !important ",
    border: "1px solid #d5d5d5 !important",
    borderRadius: "10px !important",
    fontSize: "14px !important ",
  },
  placeholder: {
    background: "none",
    color: "grey !important",
    fontSize: "0.9em",
  },

  spacingCont: {
    margin: "10px 0px !important ",
  },
  spacemargin: {
    margin: "10px 0px !important ",
  },
  goodForTextContainer: {
    width: "100%",
    height: "100%",
    borderRadius: "50% !important ",
    background: "#52459f",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  goodForText: {
    color: "#fff",
    padding: "10px",
    width: "60%",
    alignSelf: "center",
    fontSize: "0.8rem !important ",
  },

  text: {
    fontSize: "0.7rem !important ",
  },
  currencyCodeSelect: {
    borderRadius: "15px",
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  countrySelect: {
    border: "1px solid #d5d5d5 !important ",
    borderRadius: "8px !important",
    textAlign: "Left",
    "& .MuiSelect-select": {
      display: "flex !important ",
      alignItems: "center !important ",
      //justifyContent: "center !important ",
      marginLeft: "5px !important ",
      padding: "5px  !important ",
    },
  },
  spacing: {
    margin: "17px 0 0 10px",
  },
  header: {
    marginTop: "17px !important",
    background: "#52459f !important",
    color: "#fff !important ",
    padding: "2% !important ",
  },
  headerDetails: {
    display: "flex !important ",
    justifyContent: "space-between !important",
    alignItems: "center !important ",
  },
  headerstyle: {
    fontWeight: "600 !important ",
    fontSize: "14px !important",
    lineHeight: "2rem !important",
  },
  textstyle: {
    marginTop: "8px !important ",
    fontWeight: "600 !important",
    fontSize: "0.79rem !important ",
    whiteSpace: "nowrap",
  },
  flexCenterAlign: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  InfoOutlined: {
    color: "#52459f",
    fontSize: "1.2rem !important ",
    marginLeft: "0px !important ",
  },
  textarea: {
    outline: "none !important",
    border: "none !important",
    resize: "none !important",
    padding: "0px !important",
    margin: "0px !important",
    fontFamily: "Open Sans!important ",
    fontSize: "0.75rem !important ",
  },
  textFieldReadOnly: {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
  },
  mandatory: {
    color: "red ",
  },
  checkMark: {
    width: "10px !important",
    paddingLeft: "10px !important",
  },
  contentstyle: {
    fontSize: "13px !important",
  },
  infoStyle: {
    color: "Yellow !important",
    fontSize: "1.5rem !important",
  },
  infoDetails: {
    fontWeight: "600 !important",
    fontSize: "0.9rem !important",

    marginLeft: "5px !important",
  },
  space: {
    margin: "17px 0 0 10px !important",
  },
  flagAlignments: {
    display: "flex",
    gap: "10px !important ",
  },
  BankAddress: {
    fontSize: "0.7rem !important ",
    marginTop: "16px !important",
  },
  addressFlag: {
    paddingTop: "13px !important",
  },
  inputBase: {
    width: "50% !important ",
    padding: "5px !important ",
    display: "flex",
    alignItems: "center !important ",
    fontWeight: "bold !important ",
    fontSize: "0.76rem !important ",
  },
  label: {
    marginBottom: "0",
    display: "flex",
    alignItems: "center",
  },
  cancel: {
    border: "1px solid #BC64C7 !important  ",
    borderRadius: "40px !important ",
    background: "#ffff !important ",
    padding: "3px 12px 3px 12px !important",
    textTransform: "none !important ",
  },
  continue: {
    color: "#ffffff !important",
    padding: "5px 12px 5px 12px !impotant",
    background: "#51459E !important",
    textTransform: "none !important",
    borderRadius: "40px !important",
    "&:hover": {
      backgroundColor: "black !important ",
      color: "#ffffff !important ",
    },
  },
  searchIcon: {
    position: "absolute",
    right: "35px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  closeIcon: {
    position: "absolute",
    right: "10px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  circleIcon: {
    fill: "#51459E !important ",
  },
  addtxt: {
    fontSize: "14px !important ",
    fontWeight: "bold !important",
  },
  inputInput: {
    width: "100%",
    padding: "10px 0px 10px 10px",
    border: "1px solid #dcdcdc",
    borderRadius: "12px",
    fontSize: "14px",
    "&:focus": {
      border: "1px solid #dcdcdc",
      outline: "none",
    },
  },
  b_heading: {
    color: "#52459f !important",
    padding: "10px !important",
    fontSize: "14px !important ",
    fontWeight: "bold !important",
    backgroundColor: "#dddd !important",
    display: "flex !important",
    alignItems: "center !important",
  },
  avatar: {
    marginRight: "15px",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
    width: "15%",
  },
  actDropDown: {
    width: "35% !important ",
  },
  accountCard: {
    border: "1px solid #DDDDDD !important ",
    borderRadius: "16px !important ",
    marginTop: "4px !important ",
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "20px !important",
    // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
      borderRadius: "16px",
    },
  },
  sent: {
    // border: "1px solid #d5d5d5 !important",
    marginTop: "10px",
    width: "45vw !important",
    "& button": {
      borderRadius: "10px !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
    },
    "& input": {
      border: "none !important",
      // borderBottom: '1px solid #000 !important',
    },
    "& ul": {
      position: "absolute !important",
      overflowY: "auto !important",

      // WebKit scrollbar styling
      "&::-webkit-scrollbar": {
        width: "5px !important",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#52459f !important",
        borderRadius: "4px !important",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555 !important",
      },
    },
    "& .MuiSelect-select": {
      padding: "8px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "0%",
    },
  },
  currencyCodeSelect: {
    "& .custom__control": {
      width: "175px !important",
      borderRadius: "8px !important",
      border: "none !important",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    },
    "& .custom__menu-list": {
      height: "160px !important",
      overflowY: "auto !important",
      "&::-webkit-scrollbar": {
        width: "5px !important",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#52459f !important",
        borderRadius: "4px !important",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555 !important",
      },
    },
  },
  optionValue: {
    marginLeft: "10px !important ",
  },
  option: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  Currency: {
    marginLeft: "5px !important ",
    width: "20px !important ",
    height: "20px !important ",
    borderRadius: "50% !important",
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {
    headerstyle: {
      fontWeight: "600 !important ",
      fontSize: "0.79rem !important",
      lineHeight: "2rem !important",
    },
    flagAlignments: {
      display: "flex",
      gap: "10px !important ",
      alignItems: "center !important ",
    },
    contentstyle: {
      fontSize: "0.75rem !important",
    },
    textstyle: {
      fontSize: "0.75rem !important ",
    },
    widthFeilds: {
      width: "63vw",
    },
    countryFlag: {
      width: "20px !important",
      height: "20px !important",
      borderRadius: " 50% !important",
      objectFit: "cover !important ",
    },
  },
  "@media screen and (min-width:600px) and (max-width:1024px)": {
    headerstyle: {
      fontWeight: "600 !important ",
      fontSize: "1.4rem !important",
      lineHeight: "2rem !important",
    },
    contentstyle: {
      fontSize: "1.3rem !important",
    },
    textstyle: {
      fontSize: "1.3rem !important ",
    },
    textarea: {
      outline: "none !important",
      border: "none !important",
      resize: "none !important",
      fontSize: "1.3rem !important ",
    },
    textFont: {
      fontSize: "1.3rem !important ",
    },
    InfoOutlined: {
      color: "#52459f",
      fontSize: "1.3rem !important ",
      marginLeft: "0px !important ",
    },
    checkMark: {
      width: "20px !important",
      paddingLeft: "10px !important",
    },
    countryFlag: {
      width: "25px !important",
      height: "25px !important",
      borderRadius: " 50% !important",
      objectFit: "cover !important ",
    },
  },
}));
export default MobileStyles;
