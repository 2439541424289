const EuroCountries = [
  "DE",
  "FR",
  "ES",
  "IT",
  "NL",
  "NO",
  "BE",
  "AT",
  "FI",
  "PT",
  "PL",
  "IE",
  "GR",
  "CY",
  "LU",
  "LI",
  "MT",
  "SK",
  "SI",
  "SE",
  "EE",
  "LT",
  "LV",
  "BG",
  "DK",
  "HR",
  "HU",
  "RO",
  "CZ",
  "CH",
  "UA",
];

export default EuroCountries;
