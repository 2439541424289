import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import FormControl from "@mui/material/FormControl";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import AccountNumberFeild from "./CommonFields/AccountNumberFeild";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import NickNameField from "./CommonFields/NickNameField";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import ABARoutingNumberField from "./CommonFields/ABARoutingNumberField";
import Flag from "react-world-flags";
import AddressMessage from "./CommonFields/AddressMessage";
import { getCountryCode } from "../CommonUtils/CommonUtils";
import { useLocation } from "react-router-dom";

const Usa = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();
  const [disableField, setDisableField] = useState({
    BICFI: false,
    ClrSysMmbId: false,
  });

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    handleChange,
    handleRefreshInputBlur,
    //below is for edit beneficiary
    editBene,
    //below is for add beneficiary in send payment flow
    addNewBeneSendPaymentFlow,
  } = props;
  const state = useSelector((state) => state);

  const newBeneData = addNewBeneSendPaymentFlow
    ? state?.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state?.addNewBeneficiary;
  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;
    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;

  const handleEditChange = async (event) => {
    const { name, value } = event.target;

    if (name === "BICFI" || name === "ClrSysMmbId") {
      const refereshFor = ".SelectedBeneDetails." + name;
      try {
        const payLoad = {
          content: {
            SelectedBeneDetails: {
              [name]: value,
              Edit: isEditable,
            },
          },
          pageInstructions: [],
        };
        const response = await validateEditBeneficiaryFields(
          nextAssignmentId,
          payLoad,
          etag,
          refereshFor
        );
        if (name === "BICFI" && isEditable) {
          setDisableField({
            ClrSysMmbId: true,
          });
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        } else if (name === "ClrSysMmbId" && isEditable) {
          setDisableField({
            BICFI: true,
          });
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            ClrSysMmbId:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.ClrSysMmbId,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
        Edit: isEditable,
      }));
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.MaskAccNumb}
              </Typography>
            </Grid>

            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            {/* <Typography variant="body2" className={classes.headerstyle}>
                ABA Routing Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.ClrSysMmbId}
              </Typography> */}
            <ABARoutingNumberField SelectedBeneDetails={SelectedBeneDetails} />

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />

            <BeneficiaryBankAddressField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location?.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Box sx={{ minWidth: 120 }}>
                  <Typography
                    pt={2}
                    style={{ color: "#52459f" }}
                    className={classes.headerstyle}
                  >
                    Please enter beneficiary details:
                  </Typography>
                </Box>
                <NickNameField
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <BeneficiaryNameField
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  isEditable={true}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid
                  size={{ md: 6, xs: 12 }}
                  className={`${classes.spacing} ${classes.flagAlignments}`}
                >
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Account Number
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="CdtrAcctId"
                        value={newBeneData?.formState?.CdtrAcctId}
                        error={Boolean(checkErrors("CdtrAcctId"))}
                        helperText={checkErrors("CdtrAcctId")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidBeneIBANURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidBeneIBANURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <Grid
                  size={{ md: 12, xs: 12 }}
                  sx={{ display: "flex" }}
                  className={classes.spacing}
                >
                  <InfoOutlinedIcon className={classes.infoIcon} /> &nbsp;
                  <Typography
                    variant="body2"
                    style={{ color: "#52459f", fontSize: "0.85rem" }}
                  >
                    Enter BIC Code or ABA Number
                  </Typography>
                </Grid>

                <BeneficiaryBankBICCodeField
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleChange={handleChange}
                  handleBlurChange={handleRefreshInputBlur}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  flag={true}
                  country={newBeneData?.formState?.Country}
                />
                {/* ||
newBeneData?.formState?.ClrSysMmbId */}
                <Grid
                  size={{ md: 6, xs: 12 }}
                  className={`${classes.spacing} ${classes.flagAlignments}`}
                >
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        ABA Routing Number
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="ClrSysMmbId"
                        value={newBeneData?.formState?.ClrSysMmbId}
                        error={Boolean(checkErrors("ClrSysMmbId"))}
                        helperText={checkErrors("ClrSysMmbId")}
                        onChange={handleChange}
                        disabled={
                          newBeneData?.submitFlag ||
                          newBeneData?.formState?.BICFI
                        }
                        onBlur={handleRefreshInputBlur}
                      />
                    </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidABANumberURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidABANumberURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>

                <BeneficiaryBankNameField
                  beneDetails={newBeneData?.formState}
                />

                {newBeneData?.formState?.BeneBankAddress && (
                  <BeneficiaryBankAddressField
                    beneDetails={newBeneData?.formState}
                    flag={true}
                  />
                )}
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            {!isMobile && !payAgainOrRepeatTransaction && (
              <Grid container className={classes.spacing}>
                <PayToCountryFeild Country={editableBeneDetails?.Country} />

                <Grid size={{ xs: 6 }}></Grid>
              </Grid>
            )}
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              // handleBlurChange={handleEditChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                flag={flag}
                handleChange={handleInputChange}
                checkErrors={checkErrors}
              />
              <AccountNumberFeild
                editableBeneDetails={editableBeneDetails}
                isEditable={isEditable}
                flag={flag}
                handleInputChange={handleInputChange}
                checkErrors={checkErrors}
                // handleEditChange={handleEditChange}
              />

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}

              {(!payAgainOrRepeatTransaction || isEditable) && (
                <Grid
                  size={{ md: 12, xs: 12 }}
                  className={`${classes.spacing} ${classes.flex}`}
                  alignItems="center"
                >
                  <InfoOutlined className={classes.InfoOutlined} />
                  &nbsp;&nbsp;
                  <Typography variant="body2" className={classes.textstyle}>
                    Enter BIC Code or ABA Number
                  </Typography>
                </Grid>
              )}

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                flag={flag}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                checkErrors={checkErrors}
                disableFlag={disableField.BICFI}
              />

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  ABA Routing Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.ClrSysMmbId}
                    onChange={handleInputChange}
                    onBlur={handleEditChange}
                    name="ClrSysMmbId"
                    checkErrors={checkErrors}
                    disabled={disableField.ClrSysMmbId}
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.ClrSysMmbId
                      ? editableBeneDetails?.ClrSysMmbId
                      : "----"}
                  </span>
                )}
                {flag && (
                  <img
                    src={editableBeneDetails?.IsValidABANumberURL}
                    className={classes.checkMark}
                    alt="checkmark"
                  />
                )}
              </Grid>

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryBankNameField
                  beneDetails={editableBeneDetails}
                  flag={flag}
                />
              )}

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}

              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Usa;
