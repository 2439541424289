import React,  { useEffect, useState }  from "react";
import { Navigate } from "react-router-dom";
import { getAccessToken, getAccessTokenForMobile, isTokenExpired } from "../components/Authorization/Services/authService";
import { Capacitor } from "@capacitor/core";
import Loader from "../Loader";
 
const ProtectedRoute = ({ element: Element, ...rest }) => {
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    const fetchToken = async () => {
      let tokenValue;
      if (Capacitor.isNativePlatform()) {
        tokenValue = await getAccessTokenForMobile();
      } else {
        tokenValue = getAccessToken();
      }
      setToken(tokenValue);
      setIsLoading(false);
    };
    fetchToken();
  }, []);
 
  if (isLoading) {
    return <div><Loader/></div>;
  }
 
  if (token && !isTokenExpired(token)) {
    return Element;
  } else {
    return <Navigate to="/login" />;
  }
};
 
export default ProtectedRoute;