import iso3166 from "iso-3166-1";

export const getCountryCode = (countryName) => {
  const normalizedCountry = countryName?.trim()?.toLowerCase();
  let countryObj = iso3166?.all()?.find((item) => {
    const isoCountryName = item?.country?.toLowerCase()?.trim();

    return isoCountryName === normalizedCountry;
  });

  if (!countryObj) {
    countryObj = iso3166?.all()?.find((item) => {
      const isoCountryName = item?.country?.trim()?.toLowerCase();
      return isoCountryName?.includes(normalizedCountry);
    });
  }
  if (!countryObj) {
    if (
      normalizedCountry === "Korea, Republic of" ||
      normalizedCountry?.includes("korea")
    ) {
      countryObj = iso3166.all().find((item) => {
        const isoCountryName = item?.country?.toLowerCase()?.trim();
        // Checking for variations such as "Republic of Korea"
        return isoCountryName === "republic of korea";
      });
    }
  }
  if (!countryObj) {
    return null;
  }
  return countryObj?.alpha2;
};

export const formattedAddress = (input) => {
  let result = [];
  const parts = input?.replace(/^\n/, "")?.split("\n");

  for (let i = 0; i <= parts?.length; i++) {
    if (i === 0) {
      // FlatNo and FlatName combined
      result?.push(parts[i] + " - " + parts[i + 1] + ",");
      i++;
    } else if (i === parts?.length - 2) {
      // Combine country and pincode on the same line
      result?.push(parts[i] + " - " + parts[i + 1]);
      break;
    } else {
      // Other parts (street name, town, etc.)
      result?.push(parts[i] + " , " + parts[i + 1] + ",");
      i++;
    }
  }

  return result?.map((line, index) => (
    <span key={index}>
      {line}
      {index !== result?.length - 1 && <br />}
    </span>
  ));
};

export const bicfiRefreshCountries = [
  "Argentina",
  "Ghana",
  "Hong Kong",
  "Bangladesh",
  "India",
  "China",
  "Malaysia",
  "Mexico",
  "Morocco",
  "Singapore",
  "Philippines",
  "South Africa",
  "Sri Lanka",
  "Japan",
  "Kenya",
  "Chile",
  "Indonesia",
  "New Zealand",
  "Korea, Republic of", //south korea
  "Tanzania",
  "Uganda",
  "Viet Nam",
  "Uruguay",
  "Zambia",
  "Nepal",
  "Thailand",
  "Colombia",
];

export const countryGroup1 = [
  "Brazil",
  "United Arab Emirates",
  "Poland",
  "Costa Rica",
  "Pakistan",
  "Romania",
  "Ukraine",
];

export const countryGroup2 = ["United States", "Australia", "Canada", "India"];
