import React, { useState, useEffect } from "react";
import CurrencyFlag from "react-currency-flags";
import useStyles from "./styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  validateBeneficiaryDetailsReviewScreen,
  getDataForReviewBenefiToAddBenefiPage,
  validateBeneficiaryDetails,
  validateAndSaveDetails,
} from "../../CommonService/sendPaymentService";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CountrySpecificComponent from "./CountrySpecificComponent";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import flagsCode from "../../CommonService/flagServies";
import { useNavigate } from "react-router-dom";
import {
  validateAndSaveDetailsForEditBeneInSendPaymentFlow,
  resetInitialState,
} from "../../../Redux/Reducers/SendPaymentReducer";
import { useDispatch, useSelector } from "react-redux";

const ReviewBeneficiary = ({
  onContinue,
  selectedBeneDetails,
  purposeCodeList,
  nextAssignmentId,
  eTag,
  setEtag,
  setReviewDetails,
  onPrevious,
  setPreviousData,
  previousData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [purposeCode, setPurposeCode] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [editableBeneDetails, setEditableBeneDetails] =
    useState(selectedBeneDetails);
  const [visible, setvisibile] = useState(true);
  const [icon, seticon] = useState(false);

  const [flag, setFlag] = useState(true);
  const [submit, setSubmit] = useState(true);

  useEffect(() => {
    if (previousData && !selectedBeneDetails) {
      setEditableBeneDetails(
        previousData?.caseInfo?.content?.SelectedBeneDetails
      );
      setPurposeCode(
        previousData?.caseInfo?.content?.SelectedBeneDetails?.PaymentPurposeCode
      );
    }
  }, [previousData]);

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  const handleContinue = async () => {
    dispatch(resetInitialState());
    try {
      const response = await validateBeneficiaryDetailsReviewScreen(
        nextAssignmentId,
        eTag,
        editableBeneDetails
      );
      setReviewDetails(response.data);
      setEtag(response.headers.etag);
      onContinue(nextAssignmentId, eTag, setReviewDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevious = async () => {
    try {
      dispatch(resetInitialState());
      const response = await getDataForReviewBenefiToAddBenefiPage(
        editableBeneDetails.BeneBankAddress,
        nextAssignmentId,
        eTag
      ).catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
      });
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const handleValidateAndSave = async () => {
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      ValidateAndSave: true,
    }));
    const refreshFor = ".SelectedBeneDetails.ValidateAndSave";
    dispatch(
      validateAndSaveDetailsForEditBeneInSendPaymentFlow({
        editableBeneDetails: editableBeneDetails,
        nextAssignmentId: nextAssignmentId,
        eTag: eTag,
        refreshFor: refreshFor,
      })
    ).then((res) => {
      if (res.payload.status !== 422) {
        setEditableBeneDetails(res.payload);
        setFlag(true);
        setIsEditable(false);
      }
    });
    // try {
    // const response = await validateAndSaveDetails(
    //   editableBeneDetails,
    //   nextAssignmentId,
    //   eTag,
    //   refreshFor
    // );
    // setEditableBeneDetails(
    //   response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
    // );
    // setFlag(true);
    // setIsEditable(false);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const handleSave = async () => {
    // setEditableBeneDetails((prevDetails) => ({
    //   ...prevDetails,
    //   ValidateAndSave: true,
    dispatch(resetInitialState());
    // }));
    try {
      const refreshFor = ".SelectedBeneDetails.Save";
      const response = await validateAndSaveDetails(
        editableBeneDetails,
        nextAssignmentId,
        eTag,
        refreshFor
      );
      setEditableBeneDetails(
        response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
      );
      setFlag(true);
      setSubmit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
    setFlag(!flag);
    setSubmit(!submit);
    dispatch(resetInitialState());
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlevisibility = () => {
    if (visible) {
      setvisibile(false);
    }
    if (!visible) {
      setvisibile(true);
    }
    seticon((prev) => !prev);
  };
  const handleCancel = () => {
    navigate("/");
    dispatch(resetInitialState());
  };

  return (
    <div>
      <Grid container className={classes.header} size={{ xs: 12 }}>
        <Grid size={{ xs: 12 }} className={classes.headerDetails}>
          <Typography variant="body2" className={classes.headerBene}>
            {" "}
            Review Beneficiary Details
          </Typography>
          {!icon && (
            <RemoveCircleRoundedIcon
              onClick={handlevisibility}
              fontSize="small"
            />
          )}
          {icon && (
            <AddCircleRoundedIcon onClick={handlevisibility} fontSize="small" />
          )}
        </Grid>
      </Grid>
      {visible && (
        <>
          <Box className={classes.space}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "100%" }}
            >
              <Grid
                size={{ xs: 12 }}
                container
                className={classes.flexBetween}
                spacing={1}
              >
                <div className={classes.flexCenterAlign}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Pay-to Country :
                  </Typography>
                  &nbsp;
                  <Typography
                    variant="body2"
                    className={classes.flexCenterAlign}
                  >
                    <span className={classes.countryName}>
                      {selectedBeneDetails?.Country}
                    </span>
                    &nbsp;
                    <CurrencyFlag
                      currency={flagsCode[selectedBeneDetails.Country]}
                      className={classes.CurrencyFlag}
                    />
                  </Typography>
                </div>
                <EditIcon
                  fontSize="small"
                  className={classes.editIcon}
                  onClick={handleEdit}
                />
              </Grid>
            </Grid>
          </Box>
          <CountrySpecificComponent
            editableBeneDetails={editableBeneDetails}
            setEditableBeneDetails={setEditableBeneDetails}
            isEditable={isEditable}
            handleInputChange={handleInputChange}
            flag={flag}
            review={false}
            nextAssignmentId={nextAssignmentId}
            etag={eTag}
          />
        </>
      )}

      {selectedBeneDetails?.message && (
        <Box className={classes.spacing}>
          <Typography variant="body2" className={classes.headerstyle}>
            Validation message
          </Typography>
          <Typography className={classes.textFont}>
            {selectedBeneDetails.message}
          </Typography>
        </Box>
      )}
      {/* <Grid>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel style={{ margin: "10px 0 0px 10px" }}>
            Select Pay Purpose:{" "}
          </InputLabel>
          <Select
          variant="standard"
            id="PurposeCode"
            value={purposeCode}
            onChange={handlePurposeCode}
            inputProps={{ className: classes.acctInput }}
            label="Select Pay Purpose"
            disableUnderline
            displayEmpty
          >
            {purposeCodeList &&
              purposeCodeList.map((pCode) => (
                <MenuItem
                  key={pCode.PayPurPCodeDesc}
                  value={pCode?.PayPurPCodeDesc}
                >
                  {pCode?.PayPurPCode}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid> */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.spacing}
      >
        <Box className={classes.flex}>
          <Button className={classes.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handlePrevious}
            className={classes.cancel}
            style={{ marginLeft: "5px" }}
          >
            Back
          </Button>
        </Box>
        <Grid item>
          {isEditable && (
            <Button
              className={classes.continue}
              onClick={handleValidateAndSave}
              style={{ marginLeft: "3px" }}
            >
              Validate
            </Button>
          )}
          {editableBeneDetails?.HideValidateButton &&
            !submit &&
            !isEditable && (
              <Button
                className={classes.continue}
                onClick={handleSave}
                disabled={!editableBeneDetails?.EnableAPIAddress}
                style={{ marginLeft: "3px" }}
              >
                Save
              </Button>
            )}
          {submit && !isEditable && (
            <Button
              className={classes.continue}
              onClick={handleContinue}
              style={{ marginLeft: "2px" }}
            >
              Continue
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ReviewBeneficiary;
