import React, { useEffect, useState } from "react";
import CurrencyFlag from "react-currency-flags";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getListOfCountries } from "../../CommonService/sendPaymentService";
import flagsCode from "../../CommonService/flagServies";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Loader from "../Loader";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import useStyles from "./styles";
import CountrySpecificComponent from "../SendPayment/CountrySpecificComponent";
import { useNavigate } from "react-router-dom";
import {
  fetchNewBeneficiary,
  fetchCountries,
  submitBeneficiaryDetails,
  validateBeneficiary,
  initiateBeneficiary,
  setCountryNameFor,
  setFormState,
  setSubmitFlag,
  setError,
} from "../../../Redux/Reducers/addNewBeneficiaryReducer";
import {
  fetchEditBeneficiary,
  validateEditBeneficiary,
  editSubmitBeneficiaryDetails,
  setEditSubmitFlag,
} from "../../../Redux/Reducers/editBeneficiaryReducer";
const ModalPopup = (props) => {
  const { close, rowData, open, newFormFlag } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const addNewBeneficiaryData = useSelector((data) => data?.addNewBeneficiary);
  const editBeneficiaryData = useSelector((data) => data?.editBeneficiary);
  const { NewBeneDetails } = editBeneficiaryData;
  const [isEditable, setIsEditable] = useState(false);
  const navigate = useNavigate();
  const [getCountries, setGetCountries] = useState([]);
  const [getAssignmentID, setAssignmentID] = useState();
  const [formFlag, setFormFlag] = useState(addNewBeneficiaryData?.formFlag);
  const [etag, setEtag] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");

  const [countryName, setCountryName] = useState(
    addNewBeneficiaryData?.countryName
  );
  const [flag, setFlag] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleChange = (evt) => {
    console.log(evt.target.value);
    dispatch(
      setFormState({
        [evt.target.name]: evt.target.value,
      })
    );
  };
  const handleEditChange = (evt) => {
    // dispatch(
    //   setNewBeneDetails({
    //     [evt.target.name]: evt.target.value,
    //   })
    // );
  };

  useEffect(() => {
    if (!open) return;

    if (newFormFlag) {
      dispatch(fetchNewBeneficiary());
      dispatch(fetchCountries());
      setGetCountries(addNewBeneficiaryData?.countries);
      setFormFlag(false);
    } else {
      dispatch(fetchEditBeneficiary({ pyGUID: rowData.pyGUID }));
    }
  }, [open, newFormFlag]);
  const handleEdit = () => {
    setIsEditable(!isEditable);
    setFlag(!flag);
  };

  const handleCountries = (evt) => {
    const value = evt.target.value;
    const { ReceiversCountryFName, pxObjClass, ...res } = value;
    navigate("/addnewbeneficiary", { state: { country: value?.Country } });
    setSelectedCountry(value);

    dispatch(setFormState(res));
    dispatch(setCountryNameFor(value?.Country));
    dispatch(
      initiateBeneficiary({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        etag: addNewBeneficiaryData?.etag,
        countryName: value?.Country,
      })
    ).then((res) => {
      // dispatch(setFormState(res?.payload?.NewBeneDetails))
    });
  };

  const handleRefreshEditInputBlur = (event) => {
    const { name, value } = event.target;

    const bicfiRefreshCountries = [
      "Argentina",
      "Canada",
      "Ghana",
      "Hong Kong",
      "Australia",
      "Bangladesh",
      "India",
      "China",
      "Malaysia",
      "Mexico",
      "Morocco",
      "Poland",
      "Singapore",
      "Philippines",
      "South Africa",
      "Sri Lanka",
      "Japan",
    ];

    let refreshFor;

    console.log(NewBeneDetails?.Country);

    if (
      NewBeneDetails?.Country === "Brazil" ||
      NewBeneDetails?.Country === "United Arab Emirates"
    ) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneDetails.BICFI";
      } else if (name === "CdtrAcctIdIBAN") {
        refreshFor = ".NewBeneDetails.CdtrAcctIdIBAN";
      }
    } else if (NewBeneDetails?.Country === "United States") {
      if (name === "BICFI") {
        refreshFor = ".NewBeneDetails.BICFI";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneDetails.ClrSysMmbId";
      }
    } else {
      refreshFor = bicfiRefreshCountries.includes(NewBeneDetails?.Country)
        ? ".NewBeneDetails.BICFI"
        : ".NewBeneDetails.CdtrAcctIdIBAN";
    }

    dispatch(
      validateEditBeneficiary({
        assignmentID: editBeneficiaryData?.assignmentID,
        formState: NewBeneDetails,
        etag: editBeneficiaryData?.etag,
        refreshFor: refreshFor,
      })
    );
  };

  const handleValidate = () => {
    const { Edit, ...state } = addNewBeneficiaryData?.formState;
    const refreshFor = ".NewBeneDetails.Validate";
    setLoading(true);
    try {
      dispatch(
        validateBeneficiary({
          assignmentID: addNewBeneficiaryData?.assignmentID,
          formState: addNewBeneficiaryData?.formState,
          etag: addNewBeneficiaryData?.etag,
          refreshFor: refreshFor,
        })
      )
        .then((res) => {
          console.log(res);
          if (res.payload && res?.meta.requestStatus == "fulfilled") {
            dispatch(setSubmitFlag(true));
            dispatch(setError(""));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleEditValidate = () => {
    const { Edit, ...state } = NewBeneDetails;
    const refreshFor = ".NewBeneDetails.Validate";
    setLoading(true);
    dispatch(
      validateEditBeneficiary({
        assignmentID: editBeneficiaryData?.assignmentID,
        formState: state,
        etag: editBeneficiaryData?.etag,
        refreshFor: refreshFor,
      })
    )
      .then((res) => {
        if (
          res.payload &&
          res?.meta.requestStatus == "fulfilled" &&
          refreshFor === ".NewBeneDetails.Validate"
        ) {
          dispatch(setEditSubmitFlag(true));
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    const { Edit, message, pyImage, classID, EnterBICCode, ...state } =
      addNewBeneficiaryData?.formState;
    setLoading(true);
    console.log(state);
    dispatch(
      submitBeneficiaryDetails({
        assignmentID: addNewBeneficiaryData?.assignmentID,
        formState: state,
        etag: addNewBeneficiaryData?.etag,
      })
    )
      .then((res) => {
        if (res.payload?.confirmationNote) {
          close();
          navigate("/beneficiaries");
        }
      })
      .catch((error) => {
        console.error("Dispatch failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleEditSubmit = () => {
    const { Edit, message, pyImage, Country, classID, ...state } =
      NewBeneDetails;
    dispatch(
      editSubmitBeneficiaryDetails({
        assignmentID: editBeneficiaryData?.assignmentID,
        formState: state,
        etag: editBeneficiaryData?.etag,
      })
    )
      .then((res) => {
        if (res.payload?.confirmationNote) {
          close();
        }
      })
      .catch((error) => {
        console.error("Dispatch failed:", error);
      });
  };
  const handleClose = () => {
    setSelectedCountry("");
    close();
  };
  return (
    <>
      <Dialog
        // onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.Dialog}
      >
        {newFormFlag ? (
          <>
            <DialogTitle
              id="simple-dialog-title"
              className={classes.dialogTitle}
            >
              <div className={classes.popup}>
                <Typography variant="body1" className={classes.popupTitle}>
                  Add New Beneficiary
                </Typography>
                <ClearIcon
                  className={classes.ClearIcon}
                  onClick={handleClose}
                />
              </div>
            </DialogTitle>

            <DialogContent>
              <Grid container>
                <Grid size={{ md: 12 }}></Grid>
                <Grid size={{ md: 4 }}>
                  <Typography>Pay - to Country :</Typography>
                </Grid>
                <Grid size={{ md: 5 }}>
                  <Box className={classes.formselect}>
                    <FormControl fullWidth>
                      <InputLabel className={classes.inputLable}>
                        Select
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleCountries}
                        // disabled={addNewBeneficiaryData?.submitFlag}
                        size="small"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              overflow: "auto",
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {addNewBeneficiaryData?.countries &&
                          addNewBeneficiaryData?.countries.map((item) => (
                            <MenuItem value={item} key={item?.CountryCode}>
                              {item?.Country}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            {/* <DialogActions> */}
            {loading && <Loader />}
            <Grid container spacing={3} className={classes.btnDisplay}>
              <Grid>
                <Button className={classes.cancel} onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
            {/* </DialogActions> */}
          </>
        ) : (
          <>
            <DialogTitle
              id="simple-dialog-title"
              className={classes.dialogTitle}
            >
              <div className={classes.popup}>
                <Typography variant="body1" className={classes.popupTitle}>
                  Add/Modify Beneficiary
                </Typography>
                <ClearIcon
                  className={classes.ClearIcon}
                  onClick={handleClose}
                />
              </div>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={2}>
                <Grid size={{ md: 12 }} className={classes.dialogFlex}>
                  <Grid size={{ md: 6 }} className={classes.dialogFlexAround}>
                    <Typography variant="body2">Pay-to Country:</Typography>
                    <Typography variant="body2">
                      &nbsp;{NewBeneDetails?.Country}
                    </Typography>
                    {NewBeneDetails?.Country && (
                      <CurrencyFlag
                        currency={flagsCode[NewBeneDetails?.Country]}
                        className={classes.CurrencyFlag}
                      />
                    )}
                  </Grid>
                  <Grid size={{ md: 3 }}></Grid>
                  <Grid size={{ md: 3 }}>
                    <div className={classes.main}>
                      <EditIcon
                        className={classes.editIcon}
                        fontSize="small"
                        onClick={handleEdit}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid size={{ md: 12 }}>
                  <Typography variant="body2">Nickname (Optional)</Typography>
                  {isEditable ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      value={NewBeneDetails?.NickName}
                      onChange={handleEditChange}
                      name="NickName"
                    />
                  ) : NewBeneDetails?.NickName ? (
                    <span>{NewBeneDetails?.NickName}</span>
                  ) : (
                    "---"
                  )}
                </Grid>
                <Grid size={{ md: 12 }}>
                  <Grid container spacing={2}>
                    <Grid size={{ md: 6 }} className={classes.imgDisplay}>
                      <Box>
                        <Typography variant="body2">
                          Beneficiary Name
                        </Typography>
                        {isEditable ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            value={NewBeneDetails?.FullName}
                            onChange={handleEditChange}
                            name="FullName"
                          />
                        ) : (
                          <span> {NewBeneDetails?.FullName}</span>
                        )}
                      </Box>

                      {NewBeneDetails?.IsValidBeneNameURL && (
                        <Box sx={{ pt: 3 }}>
                          <img
                            src={NewBeneDetails?.IsValidBeneNameURL}
                            width={20}
                            height={20}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid size={{ md: 6 }} className={classes.imgDisplay}>
                      <Box>
                        <Typography variant="body2">Account Number</Typography>
                        {isEditable ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            value={NewBeneDetails?.CdtrAcctId}
                            onChange={handleEditChange}
                            name="CdtrAcctId"
                          />
                        ) : (
                          <span> {NewBeneDetails?.CdtrAcctId}</span>
                        )}
                      </Box>
                      {NewBeneDetails?.IsValidBeneIBANURL && (
                        <Box sx={{ pt: 3 }}>
                          <img
                            src={NewBeneDetails?.IsValidBeneIBANURL}
                            width={20}
                            height={20}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {rowData?.Country === "United States" && (
                  <Grid
                    size={{ md: 12 }}
                    spacing={2}
                    className={classes.Infostyle}
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                    <Typography variant="body2">
                      Enter BIC Code or ABA Number
                    </Typography>
                  </Grid>
                )}
                <Grid size={{ md: 12 }}>
                  <Grid container spacing={2}>
                    <Grid size={{ md: 6 }} className={classes.imgDisplay}>
                      <Box>
                        <Typography variant="body2">
                          Beneficiary Bank BIC Code
                        </Typography>
                        {isEditable ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            value={NewBeneDetails?.BICFI}
                            onChange={handleEditChange}
                            onBlur={handleRefreshEditInputBlur}
                            name="BICFI"
                          />
                        ) : (
                          <span> {NewBeneDetails?.BICFI}</span>
                        )}
                      </Box>
                      {NewBeneDetails?.IsValidBeneBICCodeURL && (
                        <Box sx={{ pt: 3 }}>
                          <img
                            src={NewBeneDetails?.IsValidBeneBICCodeURL}
                            width={20}
                            height={20}
                          />
                        </Box>
                      )}
                    </Grid>
                    {rowData?.Country === "United States" && (
                      <Grid size={{ md: 6 }} className={classes.imgDisplay}>
                        <Box>
                          <Typography variant="body2">
                            ABA Routing Number
                          </Typography>
                          {isEditable ? (
                            <TextField
                              variant="outlined"
                              size="small"
                              disabled={!isEditable}
                              value={NewBeneDetails?.ClrSysMmbId}
                              onChange={handleEditChange}
                              onBlur={handleRefreshEditInputBlur}
                              name="ClrSysMmbId"
                            />
                          ) : (
                            <span>
                              {NewBeneDetails?.ClrSysMmbId
                                ? NewBeneDetails?.ClrSysMmbId
                                : "----"}
                            </span>
                          )}
                        </Box>
                        {NewBeneDetails?.IsValidABANumberURL && (
                          <Box sx={{ pt: 3 }}>
                            <img
                              src={NewBeneDetails?.IsValidABANumberURL}
                              width={20}
                              height={20}
                            />
                          </Box>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {NewBeneDetails?.BeneBankAddress && (
                  <Grid size={{ md: 6 }} className={classes.imgDisplay}>
                    <Box>
                      <FormControl>
                        <Typography variant="body2">
                          Beneficiary Address
                        </Typography>

                        <TextareaAutosize
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={NewBeneDetails?.BeneBankAddress}
                          readOnly
                        />
                      </FormControl>
                    </Box>
                    {NewBeneDetails?.IsValidBeneAddressURL && (
                      <Box sx={{ pt: 3 }}>
                        <img
                          src={NewBeneDetails?.IsValidBeneAddressURL}
                          width={20}
                          height={20}
                        />
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={2}>
                <Grid md={6}>
                  <Button className={classes.cancel} onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
                {!editBeneficiaryData?.submitFlag && (
                  <Grid md={6}>
                    <Button
                      className={classes.cancel}
                      onClick={handleEditValidate}
                    >
                      Validate
                    </Button>
                  </Grid>
                )}
                {editBeneficiaryData?.submitFlag && (
                  <Grid md={6}>
                    <Button
                      className={classes.submit}
                      onClick={handleEditSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalPopup;
