import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "end",
  },
  btn: {
    backgroundColor: "#52459f",
    fontSize: "0.8em",
    color: "#fff",
    padding: "10px",
    textTransform: "none",
    cursor: "pointer",
    border: "none",
    height: "40px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#52459f !important",
    },
  },
  txt: {
    fontSize: "1.3em",
    fontWeight: "bold",
  },
  accountCard: {
    border: "1px solid #DDDDDD",
    borderRadius: "16px",
    paddingBottom: "20px",
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
    },
  },
  accountCard2: {
    borderRadius: "20px !important ",
    marginTop: "inherit  ",
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "20px 20px",
  },
  acContent2: {
    padding: "0px !important",
  },
  qBContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 10px 0px 10px",
  },
  quickBtn: {
    padding: "4px 16px !important ",
    backgroundColor: "#52459f !important ",
    color: "#fff",
    borderRadius: "30px !important ",
    boxShadow: "none !important",
    fontSize: "14px !important ",
    textTransform: "none !important ",
    "&:hover": {
      backgroundColor: "#014c92",
    },
  },
  divider: {
    backgroundColor: "rgba(0, 0, 0, 0.12); !important", // Match your theme or use a contrasting color
    height: "1px !important", // Increase height if needed
    marginTop: "0px !important",
    marginBottom: "20px !important", // Add margin for spacing
  },
  a_heading: {
    background: "#52549f",
    color: "#fff",
    padding: "10px",
    marginBottom: "16px !important ",
    fontSize: "14px !important ",
    fontWeight: "bold !important",
  },
  acInfo: {
    color: "#909090",
    fontSize: "14px",
    margin: "10px 0px !important ",
  },
  details: {
    marginBottom: "10px !important ",
    paddingLeft: "16px",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  subDetails: {
    paddingBottom: "10px",
    borderTop: "1px solid #ddd",
    paddingLeft: "16px",
  },
  headerTitle: {
    color: "#51459E",
    fontWeight: "bold !important",
    textDecoration: "inherit",
    textTransform: "none !important",
    fontFamily: "OpenSans, sans-serif !important",
    fontSize: "18px !important ",
    lineHeight: "1.25 !important",
    padding: "10px !important",
    // marginBottom: '10px',
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {
    headerTitle: {
      color: "#51459E",
      fontWeight: "bold !important",
      textDecoration: "inherit",
      textTransform: "none !important",
      fontFamily: "OpenSans, sans-serif !important",
      fontSize: "1rem !important ",
      lineHeight: "1.25 !important",
      padding: "10px !important",
      // marginBottom: '10px',
    },
    a_heading: {
      background: "#52549f",
      color: "#fff",
      padding: "10px",
      marginBottom: "16px !important ",
      fontSize: "0.8rem !important ",
      fontWeight: "bold !important",
    },
    quickBtn: {
      padding: "4px 16px !important ",
      backgroundColor: "#52459f !important ",
      color: "#fff",
      borderRadius: "30px !important ",
      boxShadow: "none !important",
      fontSize: "0.75rem !important ",
      textTransform: "none !important ",
      "&:hover": {
        backgroundColor: "#014c92",
      },
    },
    acInfo: {
      color: "#909090",
      fontSize: "0.75rem !important ",
      margin: "10px 0px !important ",
    },
    details: {
      fontSize: "0.8rem !important ",
      marginBottom: "10px !important ",
      paddingLeft: "16px",
    },
  },
}));

export default useStyles;
