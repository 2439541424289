import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "end",
  },
  rootContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    borderRadius: "10px",
    // height: "80vh !important ",
    // overflow: "auto !important ",
    // "&::-webkit-scrollbar": {
    //   display: "none !important ",
    // },
    // scrollbarWidth: "none !important ", // Firefox to hide scrollbars
  },
  button: {
    backgroundColor: "#52459f  !important",
    fontSize: "0.85em !important",
    // fontWeight: "500 !important",
    color: "#fff !important",
    padding: "12px !important",
    textTransform: "none !important",
    cursor: "pointer",
    border: "none !important",
    height: "38px !important",
    borderRadius: "10px !important",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#52459f !important",
    },
  },
  txt: {
    fontSize: "1.4em !important",
    fontWeight: "bold !important",
  },
  accountCard: {
    border: "1px solid #DDDDDD !important",
    borderRadius: "16px !important",
    paddingBottom: "20px !important",
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
    },
  },
  accountCard2: {
    borderRadius: "20px !important",
    border: "1px solid #e0e0e0",
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "20px 20px",
  },
  acContent2: {
    padding: "0px !important",
  },
  qBContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 10px 0px 10px",
  },
  quickBtn: {
    padding: "4px 16px !important",
    backgroundColor: "#52459f !important",
    color: "#fff !important",
    borderRadius: "30px !important",
    boxShadow: "none !important",
    fontSize: "14px !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#014c92 !important",
    },
  },
  a_heading: {
    marginTop: "10px !important",
    fontSize: "14px !important",
    fontWeight: "bold !important",
  },
  acInfo: {
    color: "#909090 !important",
    fontSize: "14px !important",
    margin: "10px 0px !important",
  },
  details: {
    marginBottom: "10px !important",
    paddingLeft: "16px !important",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  subDetails: {
    paddingBottom: "10px",
    borderTop: "1px solid #ddd",
    paddingLeft: "16px",
  },
  username: {
    lineHeight: "2.5em !important ",
  },
}));

export default useStyles;
