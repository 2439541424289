import React, { useState, useEffect } from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../../CommonService/flagServies";
import useStyles from "./styles";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import {
  validateBeneficiaryDetailsReviewScreen,
  getDataForReviewBenefiToAddBenefiPage,
  validateBeneficiaryDetails,
  validateAndSaveDetails,
} from "../../CommonService/sendPaymentService";
import {
  resetInitialState,
  validateAndSaveDetailsForEditBeneInSendPaymentFlow,
} from "../../../Redux/Reducers/SendPaymentReducer";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import CountrySpecificComponent from "./CountrySpecificComponent";
import { useDispatch, useSelector } from "react-redux";

const ReviewBeneficiary = ({
  onContinue,
  selectedBeneDetails,
  purposeCodeList,
  nextAssignmentId,
  eTag,
  setEtag,
  setReviewDetails,
  onPrevious,
  setPreviousData,
  previousData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [editableBeneDetails, setEditableBeneDetails] =
    useState(selectedBeneDetails);
  const [flag, setFlag] = useState(true);
  const [submit, setSubmit] = useState(true);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/");
    dispatch(resetInitialState());
  };

  useEffect(() => {
    if (previousData && !selectedBeneDetails) {
      setEditableBeneDetails(
        previousData?.caseInfo?.content?.SelectedBeneDetails
      );
    }
  }, [previousData]);

  const handleContinue = async () => {
    dispatch(resetInitialState());
    try {
      const response = await validateBeneficiaryDetailsReviewScreen(
        nextAssignmentId,
        eTag,
        editableBeneDetails
      );
      setReviewDetails(response.data);
      setEtag(response.headers.etag);
      onContinue(nextAssignmentId, eTag, setReviewDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevious = async () => {
    dispatch(resetInitialState());
    try {
      const response = await getDataForReviewBenefiToAddBenefiPage(
        editableBeneDetails.BeneBankAddress,
        nextAssignmentId,
        eTag
      ).catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
      });
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };
  const handleValidateAndSave = async () => {
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      ValidateAndSave: true,
    }));
    const refreshFor = ".SelectedBeneDetails.ValidateAndSave";
    // try {
    // const response = await validateAndSaveDetails(
    //   editableBeneDetails,
    //   nextAssignmentId,
    //   eTag,
    //   refreshFor
    // );
    dispatch(
      validateAndSaveDetailsForEditBeneInSendPaymentFlow({
        editableBeneDetails: editableBeneDetails,
        nextAssignmentId: nextAssignmentId,
        eTag: eTag,
        refreshFor: refreshFor,
      })
    ).then((res) => {
      if (res.payload.status !== 422) {
        setEditableBeneDetails(res.payload);
        setFlag(true);
        setIsEditable(false);
      }
    });

    // setEditableBeneDetails(
    //   response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
    // );
    // setFlag(true);
    // setIsEditable(false);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const handleSave = async () => {
    dispatch(resetInitialState());
    // setEditableBeneDetails((prevDetails) => ({
    //   ...prevDetails,
    //   ValidateAndSave: true,
    // }));
    try {
      const refreshFor = ".SelectedBeneDetails.Save";
      const response = await validateAndSaveDetails(
        editableBeneDetails,
        nextAssignmentId,
        eTag,
        refreshFor
      );
      setEditableBeneDetails(
        response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
      );
      setFlag(true);
      setSubmit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
    setFlag(!flag);
    setSubmit(!submit);
    dispatch(resetInitialState());
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  return (
    <div>
      <Grid container className={classes.spacing}>
        <Grid size={{ xs: 6, md: 6 }}>
          <Typography variant="body1" className={classes.heading}>
            Review Beneficiary Details
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, md: 6 }} className={classes.flexEnd}>
          <EditIcon
            fontSize="small"
            className={classes.editIcon}
            onClick={handleEdit}
          />
          <HelpIcon fontSize="medium" className={classes.helpIcon} />
        </Grid>
      </Grid>

      <CountrySpecificComponent
        editableBeneDetails={editableBeneDetails}
        setEditableBeneDetails={setEditableBeneDetails}
        isEditable={isEditable}
        handleInputChange={handleInputChange}
        flag={flag}
        review={false}
        nextAssignmentId={nextAssignmentId}
        etag={eTag}
      />

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.spacing}
      >
        <Box className={classes.flex}>
          <Button className={classes.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={`${classes.cancel} ${classes.optionValue}`}
            onClick={handlePrevious}
          >
            Back
          </Button>
        </Box>
        <Grid>
          {isEditable && (
            <Button className={classes.cancel} onClick={handleValidateAndSave}>
              Validate
            </Button>
          )}

          {editableBeneDetails?.HideValidateButton &&
            !submit &&
            !isEditable && (
              <Button
                className={classes.cancel}
                onClick={handleSave}
                disabled={!editableBeneDetails?.EnableAPIAddress}
              >
                Save
              </Button>
            )}
          {submit && !isEditable && (
            <Button className={classes.continue} onClick={handleContinue}>
              Continue
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ReviewBeneficiary;
