import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import {
  getListOfBeneficiaries,
  getDataForReviewBeneficiary,
  getDataForFirstPreviousPage,
} from "../../CommonService/sendPaymentService";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import flagsCode from "../../CommonService/flagServies";
import CurrencyFlag from "react-currency-flags";
import { getCountryCode } from "../../CommonUtils/CommonUtils";
import Flag from "react-world-flags";
import InfoIcon from "@mui/icons-material/Info";
import CountrySpecificComponent from "./CountrySpecificComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNewBeneficiarySendPaymentFlow,
  validateBeneficiarySendPaymentFlow,
} from "../../../Redux/Reducers/sendPaymentFlowAddNewBeneficiaryReducer";
import {
  setCaseKey,
  resetInitialState,
  setFormState,
  setSubmitFlag,
  submitNewBeneficiarySendPaymentFlow,
} from "../../../Redux/Reducers/sendPaymentFlowAddNewBeneficiaryReducer";

const BeneficiarySection = ({
  onContinue,
  receiversCountryName,
  nextAssignmentId,
  eTag,
  caseKey,
  setEtag,
  setSelectedBeneDetails,
  setPurposeCodeList,
  onPrevious,
  setPreviousData,
  previousData,
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [beneData, setBeneData] = useState([]);
  const dispatch = useDispatch();
  const addNewBeneStoreData = useSelector(
    (state) => state?.addNewBeneficiarySendPaymentFlowReducer
  );
useEffect(()=>{
dispatch(resetInitialState())
},[])
  useEffect(() => {
    dispatch(setCaseKey(caseKey));
    const fetchData = async () => {
      try {
        const response = await getListOfBeneficiaries(receiversCountryName);
        setData(response.data.data);
        setSearchData(response.data.data);
      } catch (error) {
        console.error("Error fetching Beneficiaries data:", error);
        localStorage.clear();
        navigate("/login");
      }
    };
    fetchData();
  }, [
    navigate,
    receiversCountryName,
    addNewBeneStoreData?.enableNewBeneFormFlag,
  ]);

  useEffect(() => {
    if (previousData) {
      setSelectedBeneficiary(
        previousData?.caseInfo?.content?.AvailableRecipients?.pyGUID
      );
    }
  }, [previousData]);

  const searchHandler = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    if (searchValue === "") {
      setData(searchData);
    }
    const SearchedData = searchData.filter(
      (row) =>
        row.FullName &&
        row.FullName?.toLowerCase().includes(event.target.value?.toLowerCase())
    );
    setData(SearchedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handle_row = (rowid) => {
    setSelectedBeneficiary(rowid);
  };
  const isRowSelected = (rowId) => {
    return selectedBeneficiary === rowId; // Check if the row is selected
  };

  const colors = [
    "#0b8953",
    "#c23824",
    "#2c97de",
    "#00657c",
    " #00aa66",
    "#667700",
    "#e27100",
    "#52459f",
  ];

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleContinue = async () => {
    try {
      const response = await getDataForReviewBeneficiary(
        selectedBeneficiary,
        nextAssignmentId,
        eTag
      );
      const newSelectedBeneDetails =
        response.data.data.caseInfo.content.SelectedBeneDetails;
      setSelectedBeneDetails(newSelectedBeneDetails);
      if (response.data.data.shared) {
        setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
      }
      setEtag(response.headers["etag"]);
      onContinue(nextAssignmentId);
    } catch {}
  };

  const handlePrevious = async () => {
    try {
      const response = await getDataForFirstPreviousPage(
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      if (response?.data?.data?.shared) {
        setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
      }
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);

    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  const _handleClick = (data) => {
    setRowData(data);
    setOpen(!open);
  };
  const handleCancel = () => {
    navigate("/");
  };
  function CustomTablePagination({ count, page, rowsPerPage, onPageChange }) {
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {/* Page count in the center */}
        <Typography variant="body2" sx={{ margin: "0 16px" }}>
          {`${page * rowsPerPage + 1}-${Math.min(
            count,
            (page + 1) * rowsPerPage
          )} of ${count}`}
        </Typography>

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    );
  }
  const handleAddNewBene = () => {
    dispatch(
      fetchNewBeneficiarySendPaymentFlow({ caseKey: caseKey, eTag: eTag })
    ).then((res) => {
      // console.log(res);
    });
  };
  const handleRefreshInputBlur = (event) => {
    const { name, value } = event.target;

    const bicfiRefreshCountries = [
      "Argentina",
      "Ghana",
      "Hong Kong",
      "Bangladesh",
      "India",
      "China",
      "Malaysia",
      "Mexico",
      "Morocco",
      "Singapore",
      "Philippines",
      "South Africa",
      "Sri Lanka",
      "Japan",
      "Kenya",
      "Chile",
      "Indonesia",
      "New Zealand",
      "Korea, Republic of", //south korea
      "Tanzania",
      "Uganda",
      "Viet Nam",
      "Uruguay",
      "Zambia",
      "Nepal",
      "Thailand",
      "Colombia",
    ];

    let refreshFor;

    if (
      addNewBeneStoreData?.formState?.Country === "Brazil" ||
      addNewBeneStoreData?.formState?.Country === "United Arab Emirates" ||
      addNewBeneStoreData?.formState?.Countrye === "Poland" ||
      addNewBeneStoreData?.formState?.Country === "Costa Rica" ||
      addNewBeneStoreData?.formState?.Country === "Pakistan" ||
      addNewBeneStoreData?.formState?.Country === "Romania" ||
      addNewBeneStoreData?.formState?.Country === "Ukraine"
    ) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneficiary.BICFI";
      } else if (name === "CdtrAcctIdIBAN") {
        refreshFor = ".NewBeneficiary.CdtrAcctIdIBAN";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneficiary.ClrSysMmbId";
      }
    } else if (
      addNewBeneStoreData?.formState?.Country === "United States" ||
      addNewBeneStoreData?.formState?.Country === "Australia" ||
      addNewBeneStoreData?.formState?.Country === "Canada" ||
      addNewBeneStoreData?.formState?.Country === "India"
    ) {
      if (name === "BICFI") {
        refreshFor = ".NewBeneficiary.BICFI";
      } else if (name === "ClrSysMmbId") {
        refreshFor = ".NewBeneficiary.ClrSysMmbId";
      }
    } else {
      refreshFor = bicfiRefreshCountries.includes(
        addNewBeneStoreData?.formState?.Country
      )
        ? ".NewBeneficiary.BICFI"
        : ".NewBeneficiary.CdtrAcctIdIBAN";
    }

    dispatch(
      validateBeneficiarySendPaymentFlow({
        caseKey: addNewBeneStoreData?.caseKey,
        formState: addNewBeneStoreData?.formState,
        etag: addNewBeneStoreData?.etag,
        refreshFor: refreshFor,
      })
    );
  };
  const handleValidate = () => {
    const { Edit, ...state } = addNewBeneStoreData?.formState;
    const refreshFor = ".NewBeneficiary.ValidateAndSave";
    try {
      dispatch(
        validateBeneficiarySendPaymentFlow({
          caseKey: addNewBeneStoreData?.caseKey,
          formState: addNewBeneStoreData?.formState,
          etag: addNewBeneStoreData?.etag,
          refreshFor: refreshFor,
        })
      )
        .then((res) => {
          console.log(res);
          if (res.payload && res?.meta.requestStatus == "fulfilled") {
            dispatch(setSubmitFlag(true));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {});
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (evt) => {
    console.log(evt.target.value);
    dispatch(
      setFormState({
        [evt.target.name]: evt.target.value,
      })
    );
  };
  const handleCloseForm = (evt) => {
    dispatch(resetInitialState());
  };
  const handleBeneFormSubmit = () => {
    // console.log(addNewBeneStoreData);
    dispatch(
      submitNewBeneficiarySendPaymentFlow({
        caseKey: addNewBeneStoreData?.caseKey,
        eTag: addNewBeneStoreData?.etag,
        payload: addNewBeneStoreData?.formState,
      })
    )
      .then((res) => {
        if (res.payload?.data?.confirmationNote) {
          setEtag(res.payload?.etag);
          dispatch(resetInitialState());
        }
      })
      .catch((error) => {
        console.error("Dispatch failed:", error);
      })
      .finally(() => {});
  };

  return (
    <>
      {!addNewBeneStoreData?.enableNewBeneFormFlag ? (
        <>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 12 }}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid
                  size={{ xs: 12, md: 12 }}
                  style={{ position: "relative", marginTop: "15px" }}
                >
                  <input
                    placeholder="Search for beneficiary by name"
                    className={classes.inputInput}
                    // onChange={_onSearch}
                    onChange={searchHandler}
                    value={searchText}
                  />
                  {/* <SearchIcon className={classes.searchIcon} />
              <CloseIcon className={classes.closeIcon} /> */}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              size={{ xs: 12, md: 12 }} /*  style={{ marginTop: "10px" }} */
            >
              <div className={classes.newBeneficiary}>
                <Typography variant="h6" className={classes.addtxt}>
                  Add New Beneficiary
                </Typography>
                <AddCircleIcon
                  className={classes.circleIcon}
                  onClick={handleAddNewBene}
                />
              </div>
            </Grid>

            {/* <Grid size={{xs:12,md:12}}>
          <Typography variant="body1" color="textPrimary" gutterBottom>
            <b>Add/Modify Beneficiary</b>
          </Typography>
        </Grid> */}
            <Grid size={{ xs: 12, md: 12 }}>
              <Typography variant="body1" className={classes.b_heading}>
                Available Beneficiaries in {receiversCountryName}
                <Flag
                  code={getCountryCode(receiversCountryName)}
                  className={classes.CountryFlag}
                />
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 12 }}>
              <Grid container spacing={2}>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const initials = getInitials(row.FullName);
                    const backgroundColor = getColor(initials);
                    return (
                      <>
                        <Grid size={{ xs: 12, md: 6 }} key={index}>
                          <Card
                            key={row.pxRefObjectInsName || row.pyID}
                            /* className={classes.accountCard} */ className={`${
                              classes.accountCard
                            } ${
                              isRowSelected(row.pyID)
                                ? classes.selectedRow
                                : " "
                            }`}
                            onClick={() => handle_row(row?.pyID)}
                          >
                            <CardContent className={classes.acContent}>
                              <div className={classes.CardContentDiv}>
                                <Avatar
                                  style={{ backgroundColor }}
                                  className={classes.avatar}
                                >
                                  <span className={classes.avatarFont}>
                                    {initials}
                                  </span>
                                </Avatar>
                                <div className={classes.infoContainer}>
                                  <Typography
                                    variant="body1"
                                    className={classes.avatarFullName}
                                  >
                                    {row.FullName}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.avatarAccountnum}
                                  >
                                    A/C No:{" "}
                                    {row.MaskAccNumb
                                      ? row.MaskAccNumb
                                      : row.MaskIBANNumb}
                                  </Typography>
                                </div>
                              </div>
                              <div className={classes.iconContainer}>
                                {/* <LaunchOutlinedIcon
                              fontSize="medium"
                              className={classes.avatarLaunchIcon}
                              onClick={() => {
                                _handleClick(row);
                              }}
                            /> */}
                                <DeleteOutlineOutlinedIcon
                                  fontSize="medium"
                                  className={classes.avatarDeleteIcon}
                                />
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
              {data && data.length > 0 && (
                <CustomTablePagination
                  count={data.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid size={{ md: 12 }} sx={{ display: "flex" }} i>
              <InfoIcon className={classes.infoIcon} />
              <span>
                &nbsp;Please select a beneficiary to continue further.
              </span>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid size={{ xs: 12, md: 12 }} className={classes.flexBetween}>
              <div>
                <Button className={classes.cancel} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  onClick={handlePrevious}
                  className={`${classes.cancel} ${classes.previousBtn}`}
                >
                  Back
                </Button>
              </div>
              <Button
                variant="contained"
                onClick={handleContinue}
                className={classes.continue}
                disabled={!selectedBeneficiary}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <>
            <CountrySpecificComponent
              addNewBene={true}
              handleChange={handleChange}
              newCountryName={addNewBeneStoreData?.formState?.Country}
              handleRefreshInputBlur={handleRefreshInputBlur}
              handleValidate={handleValidate}
              addNewBeneSendPaymentFlow={true}
            />

            <Grid container spacing={2} className={classes.spacingCont}>
              <Grid size={{ md: 12, xs: 12 }} className={classes.flexBetween}>
                <div>
                  <Button
                    className={`${classes.cancel} ${classes.cancelStyle}`}
                    onClick={handleCloseForm}
                  >
                    Cancel
                  </Button>
                </div>
                {!addNewBeneStoreData?.submitFlag && (
                  <Button
                    //variant="contained"
                    onClick={handleValidate}
                    className={classes.cancel}
                  >
                    Validate
                  </Button>
                )}
                {addNewBeneStoreData?.submitFlag && (
                  <Button
                    variant="contained"
                    onClick={handleBeneFormSubmit}
                    className={classes.continue}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </>
        </>
      )}
    </>
  );
};

export default BeneficiarySection;
