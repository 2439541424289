import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, Box } from "@mui/material";
import { useSelector } from "react-redux";

const BeneficiaryBankBICCodeField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    isEditable,
    flag,
    handleChange,
    handleBlurChange,
    disableFlag,
    country,
    checkErrors = () => {},
  } = props;

  return (
    <>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          {beneDetails?.Country === "Indonesia"
            ? "Beneficiary Bank BIC Code"
            : "Beneficiary BIC Code"}
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            value={beneDetails?.BICFI}
            onChange={handleChange}
            onBlur={handleBlurChange}
            error={Boolean(checkErrors("BICFI"))}
            helperText={checkErrors("BICFI")}
            name="BICFI"
            disabled={
              /* disableFlag */ country === "United States"
                ? beneDetails?.submitFlag || beneDetails?.ClrSysMmbId
                : disableFlag
            }
            className={classes.conToUppercase}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.BICFI ? beneDetails?.BICFI : "---"}
          </span>
        )}
        {flag && beneDetails?.IsValidBeneBICCodeURL && (
          <img
            src={beneDetails?.IsValidBeneBICCodeURL}
            className={classes.checkMark}
          />
        )}
      </Grid>
    </>
  );
};

export default BeneficiaryBankBICCodeField;
