import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import {
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";

const PhoneNumberFeild = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    handleChange,
    checkErrors,
    handleBlurChange,
    isEditable,
    disableFlag,
    country,
  } = props;

  return (
    <>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <FormControl>
          <Typography variant="body2" className={classes.textstyle}>
            {country === "Ghana"
              ? "Beneficiary Telephone Number"
              : "Phone Number"}
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={beneDetails?.PhoneNumber}
              onChange={handleChange}
              onBlur={handleBlurChange}
              name="PhoneNumber"
              error={Boolean(checkErrors("PhoneNumber"))}
              helperText={checkErrors("PhoneNumber")}
              disabled={disableFlag}
              className={classes.widthFeilds}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      {country === "Chile" ? (
                        <Typography>+56</Typography>
                      ) : country === "Bangladesh" ? (
                        <Typography>+880</Typography>
                      ) : country === "Argentina" ? (
                        <Typography>+54</Typography>
                      ) : country === "Guatemala" ? (
                        <Typography>+502</Typography>
                      ) : country === "China" ? (
                        <Typography>+86</Typography>
                      ) : country === "Indonesia" ? (
                        <Typography>+62</Typography>
                      ) : country === "Colombia" ? (
                        <Typography>+57</Typography>
                      ) : country === "Ghana" ? (
                        <Typography>+233</Typography>
                      ) : (
                        ""
                      )}
                    </InputAdornment>
                  ),
                },
              }}
            />
          ) : (
            <span className={classes.contentstyle}>
              {country === "Chile"
                ? "+56 "
                : country === "Bangladesh"
                ? "+880 "
                : country === "Argentina"
                ? "+54 "
                : country === "Guatemala"
                ? "+502 "
                : country === "China"
                ? "+86 "
                : country === "Indonesia"
                ? "+62 "
                : country === "Colombia"
                ? "+57 "
                : country === "Ghana"
                ? "+233 "
                : ""}
              {beneDetails?.PhoneNumber ? beneDetails?.PhoneNumber : "---"}
            </span>
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default PhoneNumberFeild;
