import axios from "axios";
import {
  getAccessToken,
  getAccessTokenForMobile,
  isTokenExpired,
  refreshAccessToken,
} from "../Authorization/Services/authService";
import endPoints, { BASE_URL } from "../../config/Api";
import { Capacitor } from "@capacitor/core";

const getHeaders = async (etag = null) => {
  let token;
  if (Capacitor.isNativePlatform()) {
    token = await getAccessTokenForMobile();
    console.log("token", token);
  } else {
    token = getAccessToken();
  }

  if (isTokenExpired(token)) {
    token = await refreshAccessToken();
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (Capacitor.isNativePlatform()) {
    headers["x-origin-channel"] = "Mobile";
  }

  if (etag) {
    headers["If-Match"] = etag;
  }

  return headers;
};

export const checkBeneficiaryExists = async (trnNumber) => {
  const dataViewParameters = JSON.stringify({ TRN: trnNumber });
  try {
    const headers = await getHeaders();
    return await axios.get(`${BASE_URL}data_views/D_CheckBeneExists`, {
      headers: headers,
      params: {
        dataViewParameters: dataViewParameters,
      },
    });
  } catch (error) {
    console.error("Error fetching data for checkBeneficiaryExists:", error);
    throw error;
  }
};
export const getPurposeCodeListApi = async (Countrycodevalue) => {
  const dataViewParameters = JSON.stringify({ Countrycode: Countrycodevalue });
  const payload = {
    dataViewParameters: { Countrycode: Countrycodevalue },
  };
  try {
    const headers = await getHeaders();
    return await axios.post(
      `${BASE_URL}data_views/D_PurposeCodeList`,
      payload,
      {
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for checkBeneficiaryExists:", error);
    throw error;
  }
};

export const createPayAgainOrRepeatTransactionCase = async (
  trnNumber,
  actionName
) => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-SendPayment",
    processID: "pyStartCase",
    content: {
      InitiatedFor: actionName,
      OldCaseTRN: trnNumber,
    },
  };
  try {
    const headers = await getHeaders();
    return await axios.post(`${BASE_URL}cases`, payload, {
      params: {
        viewType: "page",
      },
      headers: headers,
    });
  } catch (error) {
    console.error(
      "Error fetching data for createPayAgainOrRepeatTransactionCase:",
      error
    );
    throw error;
  }
};

export const createNewBeneficiary = async () => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-Beneficiary",
    processID: "pyStartCase",
    content: {},
  };
  try {
    const headers = await getHeaders();
    return await axios.post(`${BASE_URL}cases`, payload, {
      params: {
        viewType: "page",
      },
      headers: headers,
    });
  } catch (error) {
    console.error("Error fetching data for createNewBeneficiary:", error);
    throw error;
  }
};

export const initiateNewBeneficiary = async (
  assignmentID,
  countryName,
  etag
) => {
  const payload = {
    content: { Country: countryName },
  };
  try {
    const headers = await getHeaders(etag);
    return await axios.patch(
      `${BASE_URL}assignments/${assignmentID}/actions/InitiateBeneficiary`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for initiateNewBeneficiary:", error);
    throw error;
  }
};

export const validateBeneficiaryRefresh = async (
  assignmentID,
  payLoadData,
  etag,
  refreshFor
) => {
  const payloadData = refreshFor?.includes("Validate")
    ? { ...payLoadData, Validate: true }
    : payLoadData;
  const payload = {
    content: {
      NewBeneDetails: payloadData,
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(etag);
    return await axios.patch(
      `${BASE_URL}assignments/${assignmentID}/actions/EnterBeneficiaryDetails/refresh`,
      payload,
      {
        params: {
          refreshFor: refreshFor,
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryRefresh:", error);
    throw error.response || error;
  }
};

export const enterBeneficiaryDetailsFinalSubmit = async (
  assignmentID,
  payLoad,
  etag
) => {
  const payload = {
    content: {
      NewBeneDetails: payLoad,
    },
    pageInstructions: [],
  };

  try {
    const headers = await getHeaders(etag);
    return await axios.patch(
      `${BASE_URL}assignments/${assignmentID}/actions/EnterBeneficiaryDetails`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for enterBeneficiaryDetailsFinalSubmit:",
      error
    );
    throw error;
  }
};

export const modifyBeneficiary = async (pyGuid) => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-Beneficiary",
    content: {
      InitiatedFor: "BeneEdit",
      NewBeneDetails: {
        pyGUID: pyGuid,
      },
    },
  };
  try {
    const headers = await getHeaders();
    return await axios.post(`${BASE_URL}cases`, payload, {
      params: {
        viewType: "page",
      },
      headers: headers,
    });
  } catch (error) {
    console.error("Error fetching data for modifyBeneficiary:", error);
    throw error;
  }
};

export const validateEditBeneficiaryFields = async (
  assignmentID,
  payload,
  etag,
  refereshFor
) => {
  try {
    const headers = await getHeaders(etag);
    return await axios.patch(
      `${BASE_URL}assignments/${assignmentID}/actions/ValidateBeneficiaryDetails/refresh`,
      payload,
      {
        params: {
          refreshFor: refereshFor,
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for validateEditBeneficiaryFields:",
      error
    );
    throw error;
  }
};

//editbene in standalone
export const editBeneficiary = async (pyGUID) => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-Beneficiary",
    content: {
      InitiatedFor: "BeneEdit",
      NewBeneDetails: {
        pyGUID: pyGUID,
      },
    },
  };
  try {
    const headers = await getHeaders();
    return await axios.post(`${BASE_URL}cases`, payload, {
      params: {
        viewType: "page",
      },
      headers: headers,
    });
  } catch (error) {
    console.error("Error fetching data for editBeneficiary:", error);
    throw error;
  }
};
export const editValidateBeneficiaryRefresh = async (
  assignmentID,
  payLoadData,
  etag,
  refreshFor
) => {
  const payloadData = refreshFor?.includes("Validate")
    ? { ...payLoadData, Validate: true }
    : payLoadData;
  const payload = {
    content: {
      NewBeneDetails: payloadData,
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(etag);
    return await axios.patch(
      `${BASE_URL}assignments/${assignmentID}/actions/EditBeneficiaryDetails/refresh`,
      payload,
      {
        params: {
          refreshFor: refreshFor,
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryRefresh:", error);
    throw error.response || error;
  }
};

export const editBeneficiaryDetailsFinalSubmit = async (
  assignmentID,
  payLoad,
  etag
) => {
  const payload = {
    content: {
      NewBeneDetails: payLoad,
    },
    pageInstructions: [],
  };

  try {
    const headers = await getHeaders(etag);
    return await axios.patch(
      `${BASE_URL}assignments/${assignmentID}/actions/EditBeneficiaryDetails`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for enterBeneficiaryDetailsFinalSubmit:",
      error
    );
    throw error;
  }
};
// add bene in sendpayment flow
export const validateBeneficiaryRefreshInSendPaymentFlow = async (
  caseKey,
  payLoadData,
  etag,
  refreshFor
) => {
  const payloadData = refreshFor?.includes("Validate")
    ? { ...payLoadData, Validate: true }
    : payLoadData;
  const payload = {
    content: {
      NewBeneficiary: payloadData,
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(etag);
    return await axios.patch(
      `${BASE_URL}cases/${caseKey}/actions/AddBeneficiary/refresh`,
      payload,
      {
        params: {
          refreshFor: refreshFor,
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryRefresh:", error);
    throw error.response || error;
  }
};

export const addBeneficiaryInSendPaymentCaseFlow = async (caseKey, etag) => {
  try {
    const headers = await getHeaders(etag);
    return await axios.get(
      `${BASE_URL}cases/${caseKey}/actions/AddBeneficiary?excludeAdditionalActions=true&viewType=form`,
      {
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryRefresh:", error);
    throw error.response || error;
  }
};
export const submitAddBeneficiaryInSendPaymentCaseFlow = async (
  caseKey,
  etag,
  payloadData
) => {
  try {
    const {
      ValidateAndSave,
      ValidBeneficiaryAddress,
      classID,
      message,
      EnableAPIAddress,
      Edit,
      HideEdit,
      AddressMessage,
      Country,
      HideValidateButton,
      ...state
    } = payloadData;

    if (Country === "Japan") {
      delete state?.pyImage;
    }
    const payload = {
      content: {
        NewBeneficiary: state,
      },
      pageInstructions: [],
    };
    const headers = await getHeaders(etag);
    return await axios.patch(
      `${BASE_URL}cases/${caseKey}/actions/AddBeneficiary?excludeAdditionalActions=true&viewType=form`,
      payload,
      {
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryRefresh:", error);
    throw error.response || error;
  }
};

export const sendPaymentCancelApi=async(caseInsKey,etag)=>{
  try {
    const headers = await getHeaders(etag);
    return await axios.get(
      `${BASE_URL}cases/${caseInsKey}/views/PaymentCanceled`,
      {
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryRefresh:", error);
    throw error.response || error;
  }
}

export const enableAPIAddress=async(nextAssignmentId,selectedBeneDetails,eTag)=>{
  try {
    console.log(selectedBeneDetails)
    const payload = {
      content: {
        SelectedBeneDetails: selectedBeneDetails,
      },
      pageInstructions: [],
    };
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ValidateBeneficiaryDetails/refresh`,
      payload,
      {
        params: {
          refreshFor: `.SelectedBeneDetails.EnableAPIAddress`,
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryDetails:", error);
    throw error.response || error;
  }
}