import React, { useState } from "react";
import hamburger from "../../../assets/images/hamburger.svg";
import companyLogo from "../../../assets/images/EPP-logo.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import notificationsIcon from "../../../assets/images/notification.svg";
import useStyles from "./styles";
import DrawerMenu from "./drawerMenu";
import { Link } from "react-router-dom";
import { getUserName } from "../../Authorization/Services/authService";
import { AppBar, Avatar, IconButton, Toolbar, Typography } from "@mui/material";

const Navigation = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);

    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link to={"/"}>
            <img
              src={companyLogo}
              alt="portalLogo"
              className={classes.appListLogo}
            />
          </Link>
          <div className={classes.flexgrow}></div>

          <div className={classes.user}>
            <Typography variant="body2" className={classes.userText}>
              {getUserName()}
            </Typography>
            {/* <AccountCircleOutlinedIcon className={classes.userAvatar} /> */}
            <Avatar className={classes.avatar}>
              {getInitials(getUserName())}
            </Avatar>
          </div>
          {/* <IconButton className={classes.iconButton}>
            <img
              src={notificationsIcon}
              alt="NotificationsIcon"
              className={classes.notificationIcon}
            />
          </IconButton>
          <Avatar sizes="medium">{getInitials(getUserName())}</Avatar>
          <IconButton
            // color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={classes.drawerMenu}
          >
            <img
              src={hamburger}
              alt="hamburgerIcon"
              className={classes.hamburgerIcon}
            />
            
          </IconButton> */}
        </Toolbar>
      </AppBar>
      {/* <DrawerMenu
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      /> */}
    </>
  );
};
export default Navigation;
