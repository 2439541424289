import React from "react";
import useStyles from "./styles";
import rewards from "../../../assets/images/badge-reward-svgrepo-com.svg";
import budget from "../../../assets/images/money-svgrepo-com.svg";
import { Outlet, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import SocialDistanceOutlinedIcon from "@mui/icons-material/SocialDistanceOutlined";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SettingsInputComponentOutlinedIcon from "@mui/icons-material/SettingsInputComponentOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SendIcon from "@mui/icons-material/Send";
import CircleIcon from "@mui/icons-material/Circle";
import { useLocation } from "react-router-dom";

const Dataview = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <Grid container>
        <Grid size={{ xs: 12 }} className={classes.root}>
          <Grid container className={classes.rootContainer}>
            <Grid size={{ md: 2.5 }} className={classes.rightMainContainer}>
              <Grid container>
                <Grid
                  size={{ md: 12, xs: 12 }}
                  className={classes.rightSubContainer}
                >
                  <div
                    /* className={classes.rewardBudgetContainer} */ style={{
                      color: "#EDEBF4",
                    }}
                  >
                    <div
                      className={
                        location?.pathname === "/accounts"
                          ? classes.HighLight
                          : classes.listItem
                      }
                    >
                      <CreditCardOutlinedIcon fontSize="small" />
                      <Link
                        to="accounts"
                        style={{ textDecoration: "none ", color: "#EDEBF4" }}
                      >
                        <Typography>Accounts</Typography>
                      </Link>
                    </div>
                    <div className={classes.listItem}>
                      <LocalAtmOutlinedIcon fontSize="small" />
                      <Typography>Pay a bill</Typography>
                    </div>
                    <div className={classes.listItem}>
                      <SocialDistanceOutlinedIcon fontSize="small" />
                      <Typography>Domestic Transfer</Typography>
                    </div>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        backgroundColor: "rgba(68, 49, 141, 0.3)",
                        borderRadius: "8px",
                        minWidth: "23%",
                      }}
                    >
                      <div
                        className={
                          location?.pathname === "/recentpayments" ||
                          location?.pathname === "/beneficiaries" ||
                          location?.pathname === "/sendpayment" ||
                          location?.pathname === "/addnewbeneficiary" ||
                          location?.pathname === "/editbeneficiary"
                            ? classes.HighLight
                            : classes.listItem
                        }
                      >
                        <SwapHorizontalCircleOutlinedIcon fontSize="small" />
                        <Typography>International Transfer</Typography>
                      </div>

                      <div
                        className={
                          location?.pathname === "/recentpayments"
                            ? classes.paymentHighlight
                            : classes.paymentPages
                        }
                      >
                        <Link to="recentpayments" className={classes.linkStyle}>
                          <PaymentsIcon fontSize="small" />
                          <Typography
                          /* className={classes.paymentPages} */
                          >
                            Recent Payments
                          </Typography>
                        </Link>
                      </div>
                      <div
                        className={
                          location?.pathname === "/beneficiaries" ||
                          location?.pathname === "/addnewbeneficiary" ||
                          location?.pathname === "/editbeneficiary"
                            ? classes.paymentHighlight
                            : classes.paymentPages
                        }
                      >
                        <Link to="beneficiaries" className={classes.linkStyle}>
                          <PersonOutlineOutlinedIcon fontSize="small" />
                          <Typography /* className={classes.paymentPages} */>
                            Add/Modify Beneficiary
                          </Typography>
                        </Link>
                      </div>
                      <div
                        className={
                          location?.pathname === "/sendpayment"
                            ? classes.paymentHighlight
                            : classes.paymentPages
                        }
                      >
                        <Link to="sendpayment" className={classes.linkStyle}>
                          <SendIcon fontSize="small" />
                          <Typography /* className={classes.paymentPages} */>
                            Send Payment
                          </Typography>
                        </Link>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <MilitaryTechOutlinedIcon fontSize="small" />
                      <Typography>Rewards & Deals</Typography>
                    </div>
                    <div className={classes.listItem}>
                      <SettingsInputComponentOutlinedIcon fontSize="small" />
                      <Typography>Tools & Investing</Typography>
                    </div>
                    <div className={classes.listItem}>
                      <SecurityOutlinedIcon fontSize="small" />
                      <Typography>Security Center</Typography>
                    </div>
                    <div className={classes.listItem}>
                      <AccountBalanceOutlinedIcon fontSize="small" />
                      <Typography>Open an Account</Typography>
                    </div>
                    <div className={classes.listItem}>
                      <HelpOutlineOutlinedIcon fontSize="small" />
                      <Typography>Help & Support</Typography>
                    </div>
                  </div>

                  <div>
                    <Button
                      variant="outlined"
                      className={classes.logoutBtn}
                      onClick={logout}
                    >
                      Logout
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ md: 9 }} className={classes.outletContainer}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dataview;
