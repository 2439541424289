import React from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../CommonService/flagServies";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import NickNameField from "./CommonFields/NickNameField";
import Flag from "react-world-flags";
import { getCountryCode } from "../CommonUtils/CommonUtils";
import AddressMessage from "./CommonFields/AddressMessage";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import PhoneNumberFeild from "./CommonFields/PhoneNumberFeild";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import { useLocation } from "react-router-dom";

const Argentina = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();
  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    etag,
    // below is for add/new beneficiary
    addNewBene,
    newBeneCountryName,
    handleChange,
    handleRefreshInputBlur,
    handleValidate,
    addNewBeneSendPaymentFlow,
    //below is for edit beneficiary
    row,
    editBene,
  } = props;

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;
  const state = useSelector((state) => state);
  const newBeneData = addNewBeneSendPaymentFlow
    ? state.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state.addNewBeneficiary;
  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const checkErrors = (value) => {
    console.log(value);
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };
  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      Edit: isEditable,
    }));

    const refereshFor = ".SelectedBeneDetails.BICFI";
    try {
      const payLoad = {
        content: {
          SelectedBeneDetails: {
            [name]: value,
            Edit: isEditable,
          },
        },
        pageInstructions: [],
      };
      const response = await validateEditBeneficiaryFields(
        nextAssignmentId,
        payLoad,
        etag,
        refereshFor
      );
      if (name === "BICFI") {
        setEditableBeneDetails((prevDetails) => ({
          ...prevDetails,
          BICFI:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails?.BICFI,
          BeneBankAddress:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankAddress,
          BeneBankName:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankName,
          Edit: isEditable,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account Number(CBU)
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.MaskAccNumb
                  ? SelectedBeneDetails?.MaskAccNumb
                  : "---"}
              </Typography>
            </Grid>

            <PhoneNumberFeild
              beneDetails={reviewDetails?.SelectedBeneDetails}
              isEditable={false}
              country={reviewDetails?.ReceiversCountryName}
            />

            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                CUIT/CUIL
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.TaxID
                  ? SelectedBeneDetails?.TaxID
                  : "---"}
              </Typography>
            </Grid>
            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}></Grid>

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />

            <BeneficiaryBankAddressField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Box sx={{ minWidth: 120 }}>
                  <Typography
                    pt={2}
                    style={{ color: "#52459f" }}
                    className={classes.headerstyle}
                  >
                    Please enter beneficiary details:
                  </Typography>
                </Box>
                <NickNameField
                  handleChange={handleChange}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                  beneDetails={newBeneData?.formState}
                />
                <BeneficiaryNameField
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  isEditable={true}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid
                  size={{ md: 6, xs: 12 }}
                  className={`${classes.spacing} ${classes.flagAlignments}`}
                >
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Account Number (CBU)
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="CdtrAcctId"
                        value={newBeneData?.formState?.CdtrAcctId}
                        error={Boolean(checkErrors("CdtrAcctId"))}
                        helperText={checkErrors("CdtrAcctId")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidBeneIBANURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidBeneIBANURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />

                <PhoneNumberFeild
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  isEditable={true}
                  country={newBeneData?.formState?.Country}
                />
                <Grid size={{ md: 6, xs: 12 }} className={classes.spacing}>
                  <FormControl className={classes.widthFeilds}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Select the ID Type
                    </Typography>
                    <Select
                      labelId="TaxIDType"
                      id="TaxIDType"
                      name="TaxIDType"
                      error={Boolean(checkErrors("TaxIDType"))}
                      value={newBeneData?.formState?.TaxIDType}
                      helperText={checkErrors("TaxIDType")}
                      disabled={newBeneData?.submitFlag}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                    >
                      <MenuItem value={"CUIT"}>CUIT</MenuItem>
                      <MenuItem value={"CUIL"}>CUIL</MenuItem>
                    </Select>
                    {checkErrors("TaxIDType") && (
                      <FormHelperText style={{ color: "red" }}>
                        {checkErrors("TaxIDType")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {newBeneData?.formState?.TaxIDType && (
                  <Grid size={{ md: 6, xs: 12 }} className={classes.spacing}>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        {newBeneData?.formState?.TaxIDType}
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        id="TaxID"
                        size="small"
                        name="TaxID"
                        value={newBeneData?.formState?.TaxID}
                        error={Boolean(checkErrors("TaxID"))}
                        helperText={checkErrors("TaxID")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                )}
                <BeneficiaryBankBICCodeField
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleChange={handleChange}
                  handleBlurChange={handleRefreshInputBlur}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />

                <BeneficiaryBankNameField
                  beneDetails={newBeneData?.formState}
                />

                {newBeneData?.formState?.BeneBankAddress && (
                  <BeneficiaryBankAddressField
                    beneDetails={newBeneData?.formState}
                    flag={true}
                  />
                )}
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            {!isMobile && !payAgainOrRepeatTransaction && (
              <Grid container className={classes.spacing}>
                <PayToCountryFeild Country={editableBeneDetails?.Country} />

                <Grid size={{ xs: 6 }}></Grid>
              </Grid>
            )}
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              // handleBlurChange={handleEditChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Number(CBU)
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.CdtrAcctId}
                    onChange={handleInputChange}
                    // onBlur={handleEditChange}
                    error={Boolean(checkErrors("CdtrAcctId"))}
                    helperText={checkErrors("CdtrAcctId")}
                    name="CdtrAcctId"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctId
                      ? editableBeneDetails?.CdtrAcctId
                      : "---"}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidBeneIBANURL && (
                  <img
                    src={editableBeneDetails?.IsValidBeneIBANURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>
              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  CUIT/CUIL
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.TaxID}
                    onChange={handleInputChange}
                    // onBlur={handleEditChange}
                    name="TaxID"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.TaxID
                      ? editableBeneDetails?.TaxID
                      : "---"}
                  </span>
                )}
              </Grid>

              <PhoneNumberFeild
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                checkErrors={checkErrors}
                isEditable={isEditable}
                country={editableBeneDetails?.Country}
              />

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
              />

              {payAgainOrRepeatTransaction && (
                <>
                  <Grid
                    size={{ xs: 12, md: 6 }}
                    className={classes.spacing}
                  ></Grid>
                  <BeneficiaryAddressReadOnly
                    reviewDetails={editableBeneDetails}
                  />
                </>
              )}

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryBankNameField
                  beneDetails={editableBeneDetails}
                  flag={true}
                />
              )}

              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Argentina;
