import React, { useState } from "react";
import useStyles from "./styles";
import { Box, Typography, Divider } from "@mui/material";
import CurrencyFlag from "react-currency-flags";
import checkMark from "../../../assets/images/check_mark_green.svg";
import waringICon from "../../../assets/images/warningicon.svg";

import CountrySpecificComponent from "./CountrySpecificComponent";
import Grid from "@mui/material/Grid2";
import { Home } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

const FinalResponse = (props) => {
  // const { finalResponse } = props;
  const classes = useStyles();
  const location = useLocation();

  const { state } = location;
  const [finalResponse] = useState(state?.response);

  return (
    <>
      <Box className={classes.rootContainer}>
        {finalResponse?.content && (
          <>
            <div className={classes.textCenter}>
              <Link
                to={
                  // location.pathname === "/moneysent" ? "/" : "/recentpayments"
                  "/recentpayments"
                }
                className={classes.homeLink}
              >
                <Home fontSize="large" sx={{ color: "#52459f" }} />
              </Link>
              <img src={checkMark} width={50} alt="checkmark" height={50} />
              <Typography className={classes.onlyBold}>Money Sent</Typography>
            </div>

            <Grid container className={classes.finalScreen}>
              <Grid size={{ md: 12 }} className={classes.ScreenStyle} />
              <Grid size={{ md: 12 }}>
                <Typography className={classes.TransactionHeader}>
                  Transaction Summary
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account to be Debited
                </Typography>
                <Box>
                  <Typography variant="body2" className={classes.contentstyle}>
                    {finalResponse?.content?.MaskAccountNumber}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Available Balance
                </Typography>
                <Box className={classes.flex}>
                  <Typography variant=" body2" className={classes.contentstyle}>
                    {finalResponse?.content?.AccountBalance}
                  </Typography>
                  <Box className={classes.optionValue}>
                    {finalResponse?.content?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.SendCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>
              <Grid size={{ md: 12 }}>
                <Divider className={classes.spacing} />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be Sent
                </Typography>
                <Box className={classes.flexCenterAlign}>
                  <Typography className={classes.contentstyle}>
                    {finalResponse?.content?.SendAmount}
                  </Typography>
                  <Box className={classes.Sendcurrency}>
                    {finalResponse?.content?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.SendCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be Received
                </Typography>
                <Box className={classes.flexCenterAlign}>
                  <Typography className={classes.contentstyle}>
                    {finalResponse?.content?.ReceiveAmount}
                  </Typography>
                  <Box className={classes.Sendcurrency}>
                    {finalResponse?.content?.ReceiveCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Transaction Fee
                </Typography>
                <Box className={classes.flex}>
                  <Typography variant="body2" className={classes.textFont}>
                    {finalResponse?.content?.TransactionDesc}
                  </Typography>
                  <CurrencyFlag
                    currency={finalResponse?.content?.SendCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Estimated Delivery Time
                </Typography>
                <Typography className={classes.textFont}>
                  {finalResponse?.content?.EstimatedDeliveryTime}
                </Typography>
              </Grid>
              <Grid container size={{ md: 12 }} className={classes.spacing}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Foreign Exchange Rate
                  </Typography>
                  <Typography className={classes.textFont}>
                    {finalResponse?.content?.CurrencyConvDesc}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Exchange Rate Valid For
                  </Typography>
                  <Typography variant="small">
                    {finalResponse?.content?.FXRateGoodForText}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container size={{ md: 12 }} className={classes.spacing}>
                {finalResponse?.content?.PaymentPurposeCode_RVW && (
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Purpose of Payment
                    </Typography>
                    <Typography className={classes.textFont}>
                      {finalResponse?.content?.PaymentPurposeCode_RVW}
                    </Typography>
                  </Grid>
                )}
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Additional Payment Info
                  </Typography>
                  <Typography variant="small">
                    {finalResponse?.content?.Remarks}
                  </Typography>
                </Grid>
              </Grid>
              <Grid size={{ md: 12 }}>
                <Divider className={classes.spacing} />
              </Grid>
              <Grid size={{ xs: 12, md: 12 }}>
                <CountrySpecificComponent
                  review={true}
                  reviewDetails={finalResponse?.content}
                  flag={true}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!finalResponse && (
          <>
            <div className={classes.textCenter}>
              <Link
                to={
                  // location.pathname === "/moneysent" ? "/" : "/recentpayments"
                  "/recentpayments"
                }
                className={classes.homeLink}
              >
                <Home fontSize="large" sx={{ color: "#52459f" }} />
              </Link>
              <img src={waringICon} width={50} alt="checkmark" height={50} />
              <Typography className={classes.onlyBold}>
                Payment Cancelled
              </Typography>
            </div>
          </>
        )}
      </Box>
    </>
  );
};
export default FinalResponse;
