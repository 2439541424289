import React from "react";
import Grid from "@mui/material/Grid2";
import { TextField, Typography } from "@mui/material";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { useSelector } from "react-redux";

const BeneficiaryAddress = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();

  const {
    beneDetails,
    handleChange,
    isEditable,
    checkErrors,
    disableFlag,
    Country,
  } = props;
  // console.log("------>",beneDetails)
  const countryNames = ["United Arab Emirates", "Qatar"];

  return (
    <>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Flat Number / Building Number
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            value={beneDetails?.BuildingNumber}
            onChange={handleChange}
            error={Boolean(checkErrors("BuildingNumber"))}
            helperText={checkErrors("BuildingNumber")}
            disabled={disableFlag}
            name="BuildingNumber"
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.BuildingNumber ? beneDetails?.BuildingNumber : "---"}
          </span>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Flat Name / Building Name (Optional)
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            value={beneDetails?.BuildingName}
            onChange={handleChange}
            name="BuildingName"
            error={Boolean(checkErrors("BuildingName"))}
            helperText={checkErrors("BuildingName")}
            disabled={disableFlag}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.BuildingName ? beneDetails?.BuildingName : "---"}
          </span>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Street Name / Area Name
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            value={beneDetails?.StreetName}
            onChange={handleChange}
            name="StreetName"
            error={Boolean(checkErrors("StreetName"))}
            helperText={checkErrors("StreetName")}
            disabled={disableFlag}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.StreetName ? beneDetails?.StreetName : "---"}
          </span>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Town / City
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            value={beneDetails?.TownName}
            onChange={handleChange}
            name="TownName"
            error={Boolean(checkErrors("TownName"))}
            helperText={checkErrors("TownName")}
            disabled={disableFlag}
          />
        ) : (
          <span className={classes.contentstyle}>
            {beneDetails?.TownName ? beneDetails?.TownName : "---"}
          </span>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Country
        </Typography>
        <span className={classes.contentstyle}>
          {beneDetails?.Country || Country}
        </span>
      </Grid>
      {beneDetails?.Country === "Hong Kong" ? (
        <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
          <Typography variant="body2" className={classes.headerstyle}>
            Zip Code / Postal Code
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={beneDetails?.PostCode}
              onChange={handleChange}
              name="PostCode"
              
              error={Boolean(checkErrors("PostCode"))}
              helperText={checkErrors("PostCode")}
              disabled={true}
            />
          ) : (
            <span className={classes.contentstyle}>
              {beneDetails?.PostCode ? beneDetails?.PostCode : "---"}
            </span>
          )}
        </Grid>
      ) : (
        <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
          <Typography variant="body2" className={classes.headerstyle}>
            {countryNames.includes(beneDetails?.Country)
              ? `P.O Box Number`
              : `Zip Code / Postal Code`}
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={beneDetails?.PostCode}
              onChange={handleChange}
              name="PostCode"
              error={Boolean(checkErrors("PostCode"))}
              helperText={checkErrors("PostCode")}
              disabled={disableFlag}
            />
          ) : (
            <span className={classes.contentstyle}>
              {beneDetails?.PostCode ? beneDetails?.PostCode : "---"}
            </span>
          )}
        </Grid>
      )}
    </>
  );
};

export default BeneficiaryAddress;
