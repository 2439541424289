import useStyles from "./styles";
import { useEffect, useState } from "react";
import axios from "axios";
import endPoints from "../../../config/Api";
import {
  getAccessTokenForMobile,
  getUserName,
  fetchUserInfo,
} from "../../Authorization/Services/authService";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";

/* const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));
 */
const Home = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  const fetchData = async () => {
    const token = await getAccessTokenForMobile();
    fetchUserInfo(token).then((res) => {
      setUserInfo(res);
    });

    axios
      .post(
        `${endPoints.baseUrl}/data_views/${endPoints.accounts.D_Page_Name}`,
        {
          dataViewParameters: {
            AccountType: '"Checking","Savings"',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setData(data.data.data);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        navigate("/login");
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const maskedAccount = (number) => {
    return number.split("-")[0];
  };
  const displayAmount = (amount, currency) => {
    const decimalFlag = "JPY" === currency ? 0 : 2;
    const formattedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimalFlag,
      maximumFractionDigits: decimalFlag,
    }).format(amount);
    return formattedAmount === "NaN" ? "0.0" : formattedAmount;
  };
  return (
    <>
      <Grid
        container
        style={{
          padding: "16px 20px 3px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid size={{ md: 7, xs: 7 }}>
          <Grid container>
            <Grid size={{ md: 12, xs: 12 }}>
              <Typography className={classes.txt}>
                Welcome to Online Banking
              </Typography>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }}>
              <Typography
                variant="h6"
                className={classes.txt2}
                style={{ lineHeight: "2.5em" }}
              >
                {userInfo?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ md: 5, xs: 5 }} className={classes.root}>
          <Button
            color="primary"
            // onClick={handleClick}
            size="small"
            className={classes.btn}
          >
            Add Account
          </Button>
        </Grid>
      </Grid>
      <Typography variant="body1" className={classes.a_heading}>
        Personal Accounts *
      </Typography>
      <Grid container>
        {data &&
          data.map((row, index) => {
            return (
              <>
                <Grid
                  size={{ xs: 12 }}
                  style={{ padding: "8px 25px" }}
                  key={index}
                >
                  <Card
                    // key={index}
                    className={classes.accountCard2}
                  >
                    <CardContent className={classes.acContent2}>
                      <div className={classes.qBContainer}>
                        <Button
                          variant="contained"
                          className={classes.quickBtn}
                        >
                          Quick View
                        </Button>
                      </div>
                      <div className={classes.detailsContainer}>
                        <Typography variant="body1" className={classes.details}>
                          <b>
                            {displayAmount(row.AccountBalance)}&nbsp;&nbsp;
                            {row?.AccountCurrency}
                          </b>
                        </Typography>
                        <div className={classes.subDetails}>
                          <Typography
                            variant="body2"
                            className={classes.acInfo}
                          >
                            {row.AccountNumber}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.acInfo}
                          >
                            {row.AccountType} Account
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          })}
      </Grid>
      {/* <Typography variant="body1" className={classes.a_heading} style={{marginTop:'16px'}}>
        Investment Accounts *
      </Typography> */}
    </>
  );
};

export default Home;
