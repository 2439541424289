import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField, Box, TextareaAutosize } from "@mui/material";
import { useSelector } from "react-redux";

const BeneficiaryBankAddressField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const { beneDetails, flag, review, payAgainOrRepeatTransaction } = props;

  const formattedAddress = (address) => {
    return address.replace(/^\n/, "");
  };

  return (
    <>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary Bank Address
        </Typography>
        {(review || payAgainOrRepeatTransaction) && (
          <Typography variant="body2" className={classes.textFont}>
            {beneDetails?.BeneBankName && beneDetails?.BeneBankName}
          </Typography>
        )}
        <Box className={classes.flexCenterAlign}>
          <TextareaAutosize
            variant="outlined"
            fullWidth
            size="small"
            value={formattedAddress(beneDetails?.BeneBankAddress) || "--"}
            readOnly
            className={classes.textarea}
          />
          <span className={classes.addressFlag}>
            {flag && beneDetails?.IsValidBeneAddressURL && (
              <img
                src={beneDetails?.IsValidBeneAddressURL}
                className={classes.checkMark}
              />
            )}
          </span>
        </Box>
      </Grid>
    </>
  );
};

export default BeneficiaryBankAddressField;
