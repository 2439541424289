import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  /* Search bar */
  searchBar: {
    margin: "20px 0  ",
  },
  dialogStyles: {
    "& .MuiDialog-container": {
      height: "90vh !important",
    },
  },
  inputInput: {
    width: "100% !important ",
    padding: "12px !important",
    border: "1px solid #dcdcdc",
    borderRadius: "15px",
    fontSize: "12px",
    // background: "#f5f7ff",
    "&:focus": {
      border: "1px solid #dcdcdc",
      outline: "none",
    },
  },
  searchIcon: {
    position: "absolute",
    right: "60px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  closeIcon: {
    position: "absolute",
    right: "35px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  recentPaymentsGrid: {
    paddingBottom: "10px !important ",
    borderBottom: "1px solid #c7c2c2 !important ",
  },
  recentPaymentsHeader: {
    margin: "0px !important ",
    fontSize: "18px !important ",
    color: "#52459f !important ",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center", // Center button container on small screens
    },
  },
  accountCard: {
    border: "1px solid #DDDDDD",
    borderRadius: "16px !important ",
    marginTop: "10px !important ",
    //width:"86vw !important "
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between !important",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "24px 16px !important",
    // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
      borderRadius: "16px",
    },
  },
  cell: {
    whiteSpace: "nowrap",
    color: "#52459f",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px", // Adjust font size for mobile screens
    },
  },
  title: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "#52459f",
    fontSize: "16px",
    fontWeight: "bold",
    borderBottom: "1.4px solid #dddd",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px", // Adjust font size for mobile screens
    },
  },
  avatarInitial: {
    fontSize: "14px !important ",
  },
  sendBtn: {
    backgroundColor: "#52459f !important",
    color: "#fff",
    fontSize: "0.9em",
    padding: "10px 25px",
    borderRadius: "30px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#52459f",
      padding: "8px 23px",
      border: "2px solid #52459f",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em", // Adjust font size for mobile screens
      padding: "8px 20px", // Adjust padding for mobile screens
    },
  },
  RequestBtn: {
    padding: "10px 25px",
    borderRadius: "30px",
    backgroundColor: "transparent",
    fontSize: "0.9em",
    color: "#52459f",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#52459f",
      color: "#fff",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em", // Adjust font size for mobile screens
      padding: "8px 20px", // Adjust padding for mobile screens
    },
  },
  image: {
    width: "20px",
    height: "20px",
    marginLeft: "25px",
    [theme.breakpoints.down("xs")]: {
      width: "16px", // Adjust image size for mobile screens
      height: "16px", // Adjust image size for mobile screens
      marginLeft: "10px", // Adjust margin for mobile screens
    },
  },
  avatar: {
    marginRight: "15px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "10px", // Adjust margin for mobile screens
    },
  },
  flag: {
    marginTop: "-1px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px", // Adjust margin for mobile screens
    },
  },
  tableInForm: {
    marginTop: "16px",
    minWidth: 750,
    maxHeight: 550,
    overflow: "auto",
    background: "transparent",
    scrollbarWidth: "thin",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%", // Full width on small screens
      maxHeight: "400px", // Adjust height for mobile screens
    },
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff !important",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#efefef !important",
    },
    "& .MuiTableCell-root": {
      padding: "8px 8px 8px 16px",
      height: "50px",
      border: "none",
      [theme.breakpoints.down("xs")]: {
        padding: "4px 4px 4px 8px", // Adjust padding for mobile screens
        height: "40px", // Adjust height for mobile screens
      },
    },
  },
  repeatIcon: {
    cursor: "pointer",
    fill: "#54329f !important",
    paddingTop: "1px !important",
  },
  dialogTitle: {
    background: "#51459E",
    color: "#FCFBFA",
    borderRadius: "42px",
    margin: "10px !important",
    // "& div[class*='MuiDialogTitle-root']":{
    //   padding:"0px"
    // }
  },
  popup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  popupTitle: {
    fontFamily: " OpenSans, sans-serif",
    fontSize: "16px !important",
    color: "inherit",
    fontWeight: "bold !important",
    textDecoration: "inherit",
    textTransform: "none",
    paddingLeft: "10px",
  },
  clearIcon: {
    cursor: "pointer",
  },
  alertMessage: {
    textAlign: "center",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    color: "#222222 !important",
  },
  labels: {
    fontSize: "12px !important",
    fontWeight: "700 !important",
    color: "#222222 !important",
  },
  values: {
    fontFamily: "OpenSans, sans-serif",
    fontSize: "14px !important",
    color: "#4F4F4F !important",
    fontWeight: "normal !important",
    textAlign: "left !important",
  },
  avatarMargin: {
    marginRight: "15px !important ",
  },
  avatarNameGrid: {
    display: "flex !important ",
    flexDirection: "column !important ",
  },
  avatarDate: {
    fontSize: "0.85rem !important ",
    color: "#5649a1 !important ",
    fontWeight: "600 !important",
    marginTop: "10px !important",
  },
  avatarPayment: {
    fontSize: "0.85rem !important",
    fontWeight: "600 !important",
  },
  avatarPaymentDiv: {
    display: "flex !important ",
    alignItems: "center !important ",
    gap: "10px !important ",
  },
  avatarPaymentCurrency: {
    fontSize: "0.85rem !important ",
    fontWeight: "600 !important",
    marginLeft: "5px !important ",
  },
  StatusIcon: {
    width: "20px !important ",
  },
  avatarStatus: {
    fontSize: "0.85rem !important ",
    fontWeight: "600 !important",
    marginLeft: "5px !important ",
  },
  spacing: {
    marginTop: "10px !important",
  },
  divider: {
    width: "100% !important",
    marginTop: "10px !important",
  },
  repeatPmtBtn: {
    color: "#51459E !important",
    border: "1px solid #51459E !important  ",
    borderRadius: "30px !important ",
    fontSize: "0.85rem !important ",
    background: "#ffff !important ",
    padding: "3px 10px 3px 10px !important",
    textTransform: "none !important ",
    margin: "10px !important",
    "&:hover": {
      background: "#ededed !important",
    },
  },
  newAmtBtn: {
    color: "#ffffff !important",
    padding: "3px 10px 3px 10px !important",
    background: "#51459E !important",
    textTransform: "none !important",
    fontSize: "0.85rem !important ",
    borderRadius: "30px !important",
    margin: "10px !important",
    "&:hover": {
      backgroundColor: "black !important ",
      color: "#ffffff !important ",
    },
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexCenterCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  amountStatusCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  currencyFlag: {
    borderRadius: "50% !important",
    width: "20px !important",
    height: "20px !important",
    marginLeft: "5px !important",
  },
  CurrencyFlags: {
    borderRadius: "50% !important",
    width: "20px !important",
    height: "20px !important",
    marginLeft: "5px !important",
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {
    accountCard: {
      border: "1px solid #DDDDDD",
      borderRadius: "16px !important ",
      marginTop: "9px !important ",
    },
    acContent: {
      display: "flex",
      justifyContent: "space-between !important",
      alignItems: "center",
      backgroundColor: "transparent", // Ensure no conflicting background color
      padding: "12px !important",
      // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
      "&:hover": {
        backgroundColor: "#51459e20 !important", // Hover effect for Card
        borderRadius: "16px",
      },
    },
    avatarInitial: {
      fontSize: "0.7rem !important ",
    },
    avatarStyle: {
      width: "25px !important",
      height: "25px !important",
      borderRadius: "50% !important ",
    },
    avatarName: {
      fontSize: "0.7rem !important",
      marginLeft: "8px !important",
      fontWeight: "600 !important",
    },
    avatarDate: {
      fontSize: "0.7rem !important",
    },
    avatarPayment: {
      fontSize: "0.7rem !important ",
    },
    avatarStatus: {
      fontSize: "0.7rem !important ",
      fontWeight: "600 !important",
      marginLeft: "5px !important ",
    },
    avatarPaymentCurrency: {
      fontSize: "0.7rem !important ",
      marginLeft: "5px !important ",
    },
    CurrencyFlags: {
      borderRadius: "50% !important",
      width: "15px !important",
      height: "15px !important",
      marginLeft: "8px !important",
    },
    StatusIcon: {
      width: "14px !important ",
    },
    repeatPmtBtn: {
      color: "#51459E !important",
      border: "1px solid #51459E !important  ",
      borderRadius: "15px !important ",
      background: "#ffff !important ",
      fontSize: "0.7rem !important",
      padding: "3px 6px 3px 6px !important",
      textTransform: "none !important ",
      margin: "6px !important",
      "&:hover": {
        background: "#fff !important",
        color: "#51459E !important",
      },
    },
    newAmtBtn: {
      color: "#ffffff !important",
      padding: "3px 6px 3px 6px !important",
      background: "#51459E !important",
      fontSize: "0.7rem !important",
      textTransform: "none !important",
      borderRadius: "15px !important",
      margin: "6px !important",
      "&:hover": {
        backgroundColor: "#51459E !important ",
        color: "#ffffff !important ",
      },
    },
    repeatIcon: {
      cursor: "pointer",
      fill: "#54329f !important",
      paddingTop: "0px !important",
      fontSize: "small !important ",
    },
    recentPaymentsHeader: {
      margin: "0px !important ",
      fontSize: "0.85rem !important ",
      color: "#52459f !important ",
    },
    avatarMargin: {
      marginRight: "5px !important ",
      marginTop: "5px !important ",
    },
  },
  "@media screen and (min-width:600px) and (max-width:1024px)": {
    recentPaymentsHeader: {
      margin: "0px !important ",
      fontSize: "1.4rem !important ",
      color: "#52459f !important ",
    },
    inputInput: {
      width: "100% !important ",
      padding: "12px !important",
      border: "1px solid #dcdcdc",
      borderRadius: "15px",
      fontSize: "1.3rem",
      // background: "#f5f7ff",
      "&:focus": {
        border: "1px solid #dcdcdc",
        outline: "none",
      },
    },
    accountCard: {
      border: "1px solid #DDDDDD",
      borderRadius: "16px !important ",
      marginTop: "20px !important ",
      //width:"86vw !important "
    },
    acContent: {
      display: "flex",
      justifyContent: "space-between !important",
      alignItems: "center",
      backgroundColor: "transparent", // Ensure no conflicting background color
      padding: "34px 26px !important",
      // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
      "&:hover": {
        backgroundColor: "#51459e20 !important", // Hover effect for Card
        borderRadius: "16px",
      },
    },
    avatarStyle: {
      width: "50px !important",
      height: "50px !important",
      borderRadius: "50% !important ",
    },
    avatarInitial: {
      fontSize: "1.2rem !important ",
    },
    avatarName: {
      fontSize: "1.3rem !important",
      marginLeft: "10px !important",
      fontWeight: "600 !important",
    },
    avatarDate: {
      fontSize: "1.3rem !important",
      marginTop: "10px !important",
    },
    avatarPayment: {
      fontSize: "1.3rem !important ",
    },
    avatarPaymentCurrency: {
      fontSize: "1.3rem !important ",
      marginLeft: "10px !important ",
    },
    avatarStatus: {
      fontSize: "1.3rem !important ",
      fontWeight: "600 !important",
      marginLeft: "10px !important ",
    },
    CurrencyFlags: {
      borderRadius: "50% !important",
      width: "30px !important",
      height: "30px !important",
      marginLeft: "10px !important ",
    },
    repeatPmtBtn: {
      color: "#51459E !important",
      border: "1px solid #51459E !important  ",
      borderRadius: "30px !important ",
      fontSize: "1.3rem !important ",
      background: "#ffff !important ",
      padding: "3px 10px 3px 10px !important",
      textTransform: "none !important ",
      margin: "10px !important",
      "&:hover": {
        background: "#ededed !important",
      },
    },
    newAmtBtn: {
      color: "#ffffff !important",
      padding: "3px 10px 3px 10px !important",
      background: "#51459E !important",
      textTransform: "none !important",
      fontSize: "1.3rem !important ",
      borderRadius: "30px !important",
      margin: "10px !important",
      "&:hover": {
        backgroundColor: "black !important ",
        color: "#ffffff !important ",
      },
    },
    repeatIcon: {
      cursor: "pointer",
      fill: "#54329f !important",
      paddingTop: "0px !important",
      fontSize: "25px !important ",
      // width:"30px !important "
    },
    arrowIcon: {
      fontSize: "2rem !important ",
    },
    StatusIcon: {
      width: "25px !important ",
    },
    arrowContent: {
      fontSize: "1.3rem !important ",
    },
  },
}));

export default useStyles;
