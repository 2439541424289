import React from "react";
import { useState } from "react";
import companyLogo from "../../../assets/images/EPP-logo.png";
import notificationsIcon from "../../../assets/images/notification.svg";
import DrawerMenu from "../MobileDataView/drawerMenu";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { AppBar, Avatar, IconButton, Toolbar } from "@mui/material";
import { getUserName } from "../../Authorization/Services/authService";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const Navigation = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);

    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <DrawerMenu
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar
          style={{
            justifyContent: "space-between",
            // paddingTop: "19px",
            background: "#fff",
          }}
        >
          <Link to={"/"}>
            <img
              src={companyLogo}
              alt="portalLogo"
              className={classes.appListLogo}
            />
          </Link>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton style={{ margin: "5px 4px" }}>
            {/* <img
            <img
              src={notificationsIcon}
              alt="NotificationsIcon"
              className={classes.notificationIcon}
            /> */}
            <NotificationsNoneIcon style={{ color: "black" }} />
          </IconButton>
          <Link onClick={handleDrawerOpen} className={classes.linkIcons}>
            <MenuIcon style={{ color: "black" }} />
          </Link>
          {/* <Avatar style={{width:'25px', height:'25px',fontSize:"14px"}}>{getInitials(getUserName())}</Avatar> */}
        </Toolbar>
      </AppBar>
    </>
  );
};
export default Navigation;
