import { makeStyles } from "@mui/styles";
import bgImage from "../../../assets/images/bgimg.png";
import bgImageAccounts from "../../../assets/images/bgimg_beni.png";

const drawerWidth = "22%";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    width: "100vw",
    backgroundColor: "#EDEBF4 !important ",
  },
  rootContainer: {
    // height: "85vh !important ",
    backgroundColor: "#EDEBF4 !important ",

    "@media screen and (min-width: 1280px) and (max-width: 1490px) and (min-height: 800px)":
      {
        // height: "136vh !important ",
      },
    "@media screen and (min-width: 1440px) and (max-width: 1600px) and (min-height: 800px) and (max-height: 1000px)":
      {
        // height: "108vh !important ",
      },
    "@media screen and (min-width: 1601px) and (max-width: 1919px) and (min-height: 800px) and (max-height: 1000px)":
      {
        // height: "105vh !important ",
      },
    "@media screen and (min-width: 1920px) and (min-height: 900px) and (max-height: 1080px)":
      {
        // height: "98vh !important ",
      },

    "@media screen and (min-width: 2560px) and (min-height: 1000px) and (max-height: 1200px)":
      {
        // height: "98vh !important ",
      },

    "@media screen and (min-width: 3840px) and (min-height: 1200px)": {
      // height: "88vh !important ",
    },
  },
  outletContainer: {
    marginLeft: "30px !important",
    padding: "20px",
    boxSizing: "border-box",
    overflow: "auto !important ",
    backgroundColor: "#EDEBF4 !important ",

    // "&::-webkit-scrollbar": {
    //   display: "none !important ",
    // },
    // scrollbarWidth: "none !important ", // Firefox to hide scrollbars
  },

  //Right side container stylings -- Begin
  rightMainContainer: {
    backgroundColor: "#7A70F0 !important",
    padding: "10px 20px !important",
    minWidth: "250px !important ",
    // height: "85vh !important ",
    overflow: "auto !important ",
    "&::-webkit-scrollbar": {
      display: "none !important ",
    },
    scrollbarWidth: "none !important ", // Firefox to hide scrollbars
    /*  backgroundImage: `url(${bgImageAccounts})`, */
  },
  rightSubContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100% !important",
    height: "auto !important",
  },
  rewardBudgetContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    color: "#ffff",
    fontWeight: "bold !important",
    marginLeft: "10px !important",
    fontSize: "1.17em !important",
  },
  subHeading: {
    marginTop: "10px !important",
    marginLeft: "10px !important",
    color: "#fff",
    fontSize: "0.83em !important",
    fontWeight: "bold !important",
  },
  viewBtn: {
    backgroundColor: "#ffff !important",
    color: "#52459f !important",
    borderRadius: "67px !important",
    textTransform: "none !important",
    padding: "5px 20px 5px 20px !important",
    fontSize: "12px !important",
    fontWeight: "bold !important",
    marginTop: "10px !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#fff !important",
      border: "1px solid #fff !important",
    },
  },
  divider: {
    width: "100% !important",
    border: "1px #ffffff80 solid  !important",
    margin: "20px 0px !important",
  },
  listItem: {
    display: "flex !important ",
    gap: "10px !important ",
    padding: "10px !important ",
    fontSize: "10px !important",
    whiteSpace: "nowrap !important ",
    "&:hover": {
      backgroundColor: " #44318D !important  ",
      borderRadius: "10px !important ",
      minWidth: "23%",
    },
    "& .MuiTypography-root": {
      fontSize: "0.85rem !important",
      fontWeight: "500",
    },
  },
  logoutBtn: {
    color: "#EDEBF4 !important",
    fontSize: "0.9rem !important",
    fontWeight: "bold !important",
    width: "100% !important ",
    cursor: "pointer !important",
    border: "1px solid #EDEBF4!important ",
    borderRadius: "20px !important",
    marginTop: "10% !important",
    textTransform: "none !important",
  },
  linkStyle: {
    display: "flex",
    gap: "10px",
    color: "#EDEBF4 !important ",
    textDecoration: "none",
    "& .MuiTypography-root": {
      fontSize: "0.85rem !important",
      fontWeight: "500",
    },
  },
  paymentPages: {
    display: "flex !important ",
    gap: "10px !important ",
    padding: "10px !important ",
    whiteSpace: "nowrap !important ",
    "&:hover": {
      backgroundColor: "rgba(68, 49, 141, 0.6) !important  ",
      borderRadius: "10px !important ",
      marginBottom: "3px !important",
      minWidth: "23%",
    },
  },
  paymentHighlight: {
    display: "flex !important ",
    gap: "10px !important ",
    margin: "3px 0px !important",
    padding: "10px !important ",
    whiteSpace: "nowrap !important ",
    borderRadius: "10px !important ",
    minWidth: "23%",
    backgroundColor: "rgba(68, 49, 141, 0.5) !important ",
  },
  HighLight: {
    display: "flex !important ",
    margin: "3px 0px !important",
    gap: "10px !important ",
    padding: "10px !important ",
    whiteSpace: "nowrap !important ",
    borderRadius: "10px !important ",
    minWidth: "23%",
    backgroundColor: "#44318D !important ",
    "& .MuiTypography-root": {
      fontSize: "0.85rem !important",
      fontWeight: "500",
    },
  },
}));

export default useStyles;
