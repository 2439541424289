import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { Box, Typography, Divider } from "@mui/material";
import CurrencyFlag from "react-currency-flags";
import checkMark from "../../../assets/images/check_mark_green.svg";
import Grid from "@mui/material/Grid2";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CountrySpecificComponent from "./CountrySpecificComponent";
import { useLocation } from "react-router-dom";
import warningIcon from "../../../assets/images/warningicon.svg"
const FinalResponse = (props) => {
  // const { finalResponse } = props;
  const classes = useStyles();
  const [icon, seticon] = useState(false);
  const [visible, setvisibile] = useState(true);
  const location = useLocation();

  const { state } = location;
  const [finalResponse] = useState(state?.response);

  const handlevisibility = () => {
    if (visible) {
      setvisibile(false);
    } else {
      setvisibile(true);
    }
    seticon((prev) => !prev);
  };

  return (
    <>
      <Box className={classes.rootContainer}>
        {finalResponse?.content && (
          <>
            <Grid container className={classes.finalheader}>
              <Grid size={{ xs: 12 }} className={classes.finalDetails}>
                <Typography variant="body2" className={classes.headertext}>
                  Transaction Details
                </Typography>{" "}
                {icon && <RemoveCircleRoundedIcon onClick={handlevisibility} />}
                {!icon && <AddCircleRoundedIcon onClick={handlevisibility} />}
              </Grid>
            </Grid>
            {!visible && (
              <>
                {/* <div className={classes.textCenter}>
              <img src={checkMark} width={50} alt="checkmark" height={50} />
              <Typography>Money Sent</Typography>
            </div> */}
                <Grid container className={classes.finalScreen}>
                  <Grid size={{ md: 12 }} className={classes.ScreenStyle} />
                  <Grid size={{ md: 12 }}>
                    <Typography className={classes.TransactionHeader}>
                      Transaction Summary
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Account to be Debited
                    </Typography>
                    <Box>
                      <Typography
                        variant="body2"
                        className={classes.contentstyle}
                      >
                        {finalResponse?.content?.MaskAccountNumber}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Available Balance
                    </Typography>
                    <Box className={classes.flex}>
                      <Typography
                        variant=" body2"
                        className={classes.contentstyle}
                      >
                        {finalResponse?.content?.AccountBalance}
                      </Typography>
                      <Box
                        className={`${classes.optionValue} ${classes.textFont}`}
                      >
                        {finalResponse?.content?.SendCurrency}
                      </Box>
                      <CurrencyFlag
                        currency={finalResponse?.content?.SendCurrency}
                        className={classes.currencyStyle}
                      />
                    </Box>
                  </Grid>
                  <Grid size={{ md: 12 }}>
                    {" "}
                    <Divider className={classes.spacing} />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Amount to be Sent
                    </Typography>
                    <Box className={classes.flexCenterAlign}>
                      <Typography
                        variant="body2"
                        className={classes.contentstyle}
                      >
                        {finalResponse?.content?.SendAmount}
                      </Typography>
                      <Box
                        className={`${classes.optionValue} ${classes.textFont}`}
                      >
                        {finalResponse?.content?.SendCurrency}
                      </Box>
                      <CurrencyFlag
                        currency={finalResponse?.content?.SendCurrency}
                        className={classes.currencyStyle}
                      />
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Amount to be Received
                    </Typography>
                    <Box className={classes.flexCenterAlign}>
                      <Typography
                        variant="body2"
                        className={classes.contentstyle}
                      >
                        {finalResponse?.content?.ReceiveAmount}
                      </Typography>
                      <Box
                        className={`${classes.optionValue} ${classes.textFont}`}
                      >
                        {finalResponse?.content?.ReceiveCurrency}
                      </Box>
                      <CurrencyFlag
                        currency={finalResponse?.content?.ReceiveCurrency}
                        className={classes.currencyStyle}
                      />
                    </Box>
                  </Grid>

                  <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Transaction Fee
                    </Typography>
                    <Box className={classes.flex}>
                      <Typography variant="body2" className={classes.textFont}>
                        {finalResponse?.content?.TransactionDesc}
                      </Typography>
                      <CurrencyFlag
                        currency={finalResponse?.content?.SendCurrency}
                        className={classes.currencyStyle}
                      />
                    </Box>
                  </Grid>

                  <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Estimated Delivery Time
                    </Typography>
                    <Typography className={classes.textFont}>
                      {finalResponse?.content?.EstimatedDeliveryTime}
                    </Typography>
                  </Grid>
                  <Grid container size={{ md: 12 }} className={classes.spacing}>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Foreign Exchange Rate
                      </Typography>
                      <Typography className={classes.textFont}>
                        {finalResponse?.content?.CurrencyConvDesc}
                      </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Exchange Rate Valid For
                      </Typography>
                      <Typography className={classes.textFont}>
                        {finalResponse?.content?.FXRateGoodForText}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    size={{ xs: 12, md: 12 }}
                    className={classes.spacing}
                  >
                    {finalResponse?.content?.PaymentPurposeCode_RVW && (
                      <Grid size={{ xs: 12, md: 6 }}>
                        <Typography
                          variant="body2"
                          className={classes.headerstyle}
                        >
                          Purpose of Payment
                        </Typography>
                        <Typography className={classes.textFont}>
                          {finalResponse?.content?.PaymentPurposeCode_RVW}
                        </Typography>
                      </Grid>
                    )}
                    <Grid size={{ xs: 12, md: 6 }}>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Remarks
                      </Typography>
                      <Typography className={classes.textFont}>
                        {finalResponse?.content?.Remarks}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid size={{ md: 12 }}>
                    {" "}
                    <Divider className={classes.spacing} />
                  </Grid>
                  <Grid size={{ xs: 12, md: 12 }}>
                    <CountrySpecificComponent
                      review={true}
                      reviewDetails={finalResponse?.content}
                      flag={true}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
        {visible && (
          <div style={{ marginTop: "30px" }}>
            <div className={classes.textCenter}>
              <img
                src={checkMark}
                alt="checkmark"
                className={classes.moneySentImg}
              />
              <Typography className={classes.moneySent}>Money Sent</Typography>
              {/* <span className={classes.successMsg}>
                Your transfer will proceed <br />
                in next 30 minutes
              </span> */}
            </div>
          </div>
        )}
         {!finalResponse && (
          <>
           <div style={{ marginTop: "30px" }}>
            <div className={classes.textCenter}>
              <img
                src={warningIcon}
                alt="checkmark"
                className={classes.moneySentImg}
              />
              <Typography className={classes.moneySent}>Payment cancelled</Typography>
              {/* <span className={classes.successMsg}>
                Your transfer will proceed <br />
                in next 30 minutes
              </span> */}
            </div>
          </div>
          </>
        )}
      </Box>
    </>
  );
};
export default FinalResponse;
