import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getCountryCode } from "../../CommonUtils/CommonUtils";
import Flag from "react-world-flags";

const PayToCountryFeild = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const { Country } = props;

  return (
    <>
      <Grid size={{ xs: 12 }} className={classes.flexCenterAlign}>
        <Typography variant="body2" className={classes.textstyle}>
          <b>Pay to Country :</b>
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "5px 0 0 5px",
          }}
        >
          <Typography variant="body2">{Country} &nbsp;</Typography>

          <Flag
            code={getCountryCode(Country)}
            className={classes.countryFlag}
          />
        </div>
      </Grid>
    </>
  );
};

export default PayToCountryFeild;
