import React from "react";
import { Box, TextField, Typography, FormControl } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import RoutingCodeField from "./CommonFields/RoutingCodeField";
import AddressMessage from "./CommonFields/AddressMessage";
import NickNameField from "./CommonFields/NickNameField";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";
import BeneficiaryBankAddressField from "./CommonFields/BeneficiaryBankAddressField";
import BeneficiaryBankBICCodeField from "./CommonFields/BeneficiaryBankBICCodeField";
import BeneficiaryBankNameField from "./CommonFields/BeneficiaryBankNameFeild";
import PayToCountryFeild from "./CommonFields/PayToCountryFeild";
import { useLocation } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs"; // just import dayjs
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import { useState } from "react";

const SouthKorea = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(null);

  // Get current date and subtract 150 years
  const today = dayjs();
  const minDate = today.subtract(150, "years"); // Use dayjs for minDate
  const maxDate = today; // No future dates

  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    handleChange,
    handleDateChange,
    handleRefreshInputBlur,
    //below is for edit beneficiary
    editBene,
    //below is for add beneficiary in send payment flow
    addNewBeneSendPaymentFlow,
  } = props;

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;
  const state = useSelector((state) => state);

  const newBeneData = addNewBeneSendPaymentFlow
    ? state?.addNewBeneficiarySendPaymentFlowReducer
    : editBene
    ? state?.editBeneficiary
    : state?.addNewBeneficiary;

  const errors = newBeneData?.error || state?.sendPaymentReducer?.error;
  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };

  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    if (name === "BICFI" || name === "ClrSysMmbId") {
      const refereshFor = ".SelectedBeneDetails." + name;
      try {
        const payLoad = {
          content: {
            SelectedBeneDetails: {
              [name]: value,
              Edit: isEditable,
            },
          },
          pageInstructions: [],
        };
        const response = await validateEditBeneficiaryFields(
          nextAssignmentId,
          payLoad,
          etag,
          refereshFor
        );
        if (name === "BICFI") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        } else if (name === "ClrSysMmbId") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            ClrSysMmbId:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.ClrSysMmbId,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            BeneBankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankName,
            Edit: isEditable,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
        Edit: isEditable,
      }));
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account Number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.MaskAccNumb
                  ? SelectedBeneDetails?.MaskAccNumb
                  : "---"}
              </Typography>
            </Grid>

            <RoutingCodeField SelectedBeneDetails={SelectedBeneDetails} />

            <BeneficiaryBankBICCodeField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />

            <BeneficiaryAddressReadOnly
              reviewDetails={reviewDetails}
              review={review}
            />

            <BeneficiaryBankAddressField
              beneDetails={SelectedBeneDetails}
              review={review}
            />
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              {location.pathname === "/sendpayment" && (
                <Grid container className={classes.spacing}>
                  <PayToCountryFeild
                    Country={newBeneData?.formState?.Country}
                  />
                </Grid>
              )}
              <Grid container spacing={1}>
                <Box sx={{ minWidth: 120 }}>
                  <Typography
                    pt={2}
                    style={{ color: "#52459f" }}
                    className={classes.headerstyle}
                  >
                    Please enter beneficiary details:
                  </Typography>
                </Box>
                <NickNameField
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <BeneficiaryNameField
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  isEditable={true}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                  <Typography>Date of Birth</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        name="DOB"
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(newValue) =>
                          handleDateChange(newValue, "DOB")
                        }
                        className={classes.datePicker}
                        value={selectedDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(checkErrors("DOB"))}
                            helperText={checkErrors("DOB")}
                            sx={{
                              width: "140px !important",
                              height: "40px !important",
                              fontSize: "14px",
                              boxSizing: "border-box",
                              "& .MuiInputBase-root": {
                                height: "40px !important",
                                overflow: "hidden",
                              },

                              "& .MuiDialog-root": {
                                overflow: "hidden",
                              },

                              "&::-webkit-scrollbar": {
                                display: "none",
                              },
                            }}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                {/*  <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Date of Birth
                  </Typography>
                  <TextField
                    variant="outlined"
                    //fullWidth
                    size="small"
                    name="DOB"
                    error={Boolean(checkErrors("DOB"))}
                    helperText={checkErrors("DOB")}
                    disabled={newBeneData?.submitFlag}
                    onChange={handleChange}
                  />
                </Grid> */}
                <Grid size={{ md: 6, xs: 12 }} className={classes.spacing}>
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Account Number
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="CdtrAcctId"
                        error={Boolean(checkErrors("CdtrAcctId"))}
                        helperText={checkErrors("CdtrAcctId")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidAccountTypeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidAccountTypeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />

                <BeneficiaryBankBICCodeField
                  beneDetails={newBeneData?.formState}
                  isEditable={true}
                  handleChange={handleChange}
                  handleBlurChange={handleRefreshInputBlur}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />

                <BeneficiaryBankNameField
                  beneDetails={newBeneData?.formState}
                />
                {newBeneData?.formState?.BeneBankAddress && (
                  <BeneficiaryBankAddressField
                    beneDetails={newBeneData?.formState}
                    flag={true}
                  />
                )}
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            {!isMobile && !payAgainOrRepeatTransaction && (
              <Grid container className={classes.spacing}>
                <PayToCountryFeild Country={editableBeneDetails?.Country} />

                <Grid size={{ xs: 6 }}></Grid>
              </Grid>
            )}
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              // handleBlurChange={handleEditChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.CdtrAcctId}
                    onChange={handleInputChange}
                    // onBlur={handleEditChange}
                    error={Boolean(checkErrors("CdtrAcctId"))}
                        helperText={checkErrors("CdtrAcctId")}
                    name="CdtrAcctId"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctId
                      ? editableBeneDetails?.CdtrAcctId
                      : "---"}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidAccountTypeURL && (
                  <img
                    src={editableBeneDetails?.IsValidAccountTypeURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}

              <RoutingCodeField
                editableBeneDetails={editableBeneDetails}
                handleInputChange={handleInputChange}
                handleBlurChange={handleEditChange}
                isEditable={isEditable}
              />

              <BeneficiaryBankBICCodeField
                beneDetails={editableBeneDetails}
                isEditable={isEditable}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                flag={flag}
                checkErrors={checkErrors}
              />

              {!payAgainOrRepeatTransaction && (
                <BeneficiaryBankNameField
                  beneDetails={editableBeneDetails}
                  flag={true}
                />
              )}

              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}

              <BeneficiaryBankAddressField
                beneDetails={editableBeneDetails}
                flag={flag}
                payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
              />
            </Grid>
<AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
              etag={etag}
              nextAssignmentId={nextAssignmentId}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default SouthKorea;
