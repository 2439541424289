import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    position: "absolute",
    right: "38px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  rootContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    borderRadius: "10px",
    // height: "80vh !important ",
    // overflow: "auto !important ",
    // "&::-webkit-scrollbar": {
    //   display: "none !important ",
    // },
    // scrollbarWidth: "none !important ", // Firefox to hide scrollbars
  },
  inputLable: {
    margin: "-3px 0 0px 0px !important ",
    fontSize: "14px !important",
    color: "#222222 !important",
  },
  closeIcon: {
    position: "absolute",
    right: "8px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  circleIcon: {
    fill: "#51459E !important",
    cursor: "pointer",
  },
  addtxt: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  SearchInput: {
    border: "1px solid #ddd !important",
    color: "#4F4F4F",
    fontSize: "14px",
    borderRadius: "15px !important",
    lineHeight: "38px",
    height: "40px !important",
    fontWeight: "normal",
    textDecoration: "inherit",
    textTransform: "none",
    margin: "10px 0px",
    marginLeft: "1px",
    padding: "0 32px 0 16px",
    width: " -webkit-fill-available",
    background: " #FFF !important",
    "&:focus": {
      boxShadow:
        "0 0 0 0.11rem #fff, 0 0 0 0.18rem #295ED9, 0 0 0 0.3rem #295ED91a",
      outline: "none",
    },
  },
  title: {
    marginTop: `${theme.spacing(2)} !important`,
    paddingLeft: `${theme.spacing(2)} !important`,
    color: "#52459f !important",
    fontSize: "16px !important",
    fontWeight: "bold !important",
    borderBottom: "1.4px solid #dddd",
  },
  beneficiaryheading: {
    color: "#52459f",
    padding: "7px",
    marginTop: "10px",
    marginBottom: "20px",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    backgroundColor: "#dddd",
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "20px",
    height: "20px",
    marginLeft: "25px",
  },
  avatar: {
    marginRight: "15px",
  },
  flag: {
    marginTop: "-1px",
    display: "flex",
    justifyContent: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
    width: "15%",
  },
  accountCard: {
    border: "1px solid #DDDDDD",
    borderRadius: "16px !important",
    paddingBottom: "20px",
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
    },
  },
  accountContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    // padding: '20px 20px'
    padding: "22px 16px 3px 16px !important",
    "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
  },
  popup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: " 10px 5px",
    width: "100%",
  },
  popupTitle: {
    fontFamily: " OpenSans, sans-serif",
    fontSize: "16px",
    color: "inherit",
    fontWeight: "bold",
    textDecoration: "inherit",
    textTransform: "none",
    lineHeight: "1.25",
    paddingLeft: "10px",
  },
  dialogTitle: {
    background: "#51459E",
    color: "#FCFBFA",
    borderRadius: "10px",
    margin: "10px !important",
  },
  Dialog: {
    "& .MuiDialog-paper": {
      width: " 600px",
      borderRadius: "10px !important",
    },
  },
  main: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    gap: "10px !important",
  },
  editIcon: {
    fill: "#fff !important",
    background: "#52439E !important",
    borderRadius: "7px",
    padding: "3px",
    cursor: "pointer",
    height: "15px",
    width: " 18px",
  },
  imgDisplay: {
    display: "flex !important",
    gap: "10px !important",
  },
  submit: {
    backgroundColor: "#51459E !important",
    color: "#FFFF !important",
    borderRadius: "20px !important",
    fontSize: " 14px !important",
    padding: " 0 16px 0 16px !important",
    height: "32px !important",
    textTransform: "none !important",
  },
  cancel: {
    border: "1px solid #BC64C7 !important",
    backgroundColor: " #FFFFFF !important",
    height: "32px !important",
    padding: " 0 16px 0 16px !important",
    fontSize: "14px",
    color: "#87269B !important",
    fontWeight: "600 !important",
    borderRadius: "20px !important",
    outline: "none !important",
    textTransform: "none !important",
  },
  searchbar: {
    position: "relative",
  },
  addBeneficiary: {
    display: "flex",
    gap: "10px",
    marginLeft: "5px",
  },
  headergrid: {
    marginLeft: "3px",
  },
  cardContent: {
    display: "flex",
    width: "80%",
  },
  FullName: {
    color: "#222",
    fontWeight: "600 !important ",
    fontSize: "14px !important ",
  },
  Accountnumber: {
    color: "#52459f",
    fontSize: "13px !important",
    fontWeight: "400 !important",
  },
  LaunchOutlinedIcon: {
    color: "#52459f",
    marginRight: "14px !important",
    cursor: "pointer",
  },
  DeleteIcon: {
    color: "#52459f",
    cursor: "pointer",
  },
  /* Dialog component styling  */
  ClearIcon: {
    cursor: "pointer",
  },
  formselect: {
    minWidth: 120,
  },
  dialogFlex: {
    display: "flex",
  },
  dialogFlexAround: {
    display: "flex",
    justifyContent: "space-around",
  },
  CurrencyFlag: {
    borderRadius: "50% !important ",
    width: "20px !important ",
    height: "20px !important ",
  },
  Infostyle: {
    display: "flex !important ",
    gap: "3px !important ",
  },
  Infoicon: {
    fill: "#52439E !important",
    height: "20px !important ",
  },
  Buttongrid: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
  },
  infoIcon: {
    fill: "#52439E !important",
    height: "20px",
  },
  imgDisplay: {
    display: "flex !important",
    gap: "10px !important",
  },
  btnDisplay: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 10px 14px",
  },
}));

export default useStyles;
